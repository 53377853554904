import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import {
    Container, Row, Col, Image,
} from 'react-bootstrap';

const Logo = (props) => (
    <div className="gs-logo pb-3 pl-1 pr-1 d-inline-block align-items-center">
        <Image className="d-inline-block" alt="sponsor logo" src={props.img} fluid />
    </div>
);

const LogoSection = (props) => {
    const logos = props.logoSection.map((logoImg, i) => <Logo key={i} img={logoImg} />);
    return (
        <Row className="align-items-center">
            <Col className="text-center">
                {logos}
            </Col>
        </Row>
    );
};

const CompaniesLogoComponent = (props) => {
    const [sliderObj, setSlider] = useState(0);
    const [sliderOn, setSliderOn] = useState(false);
    const logoSections = props.logoSections.map((logoSection, i) => <LogoSection key={i} logoSection={logoSection} />);
    const settings = {
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 700,
        arrows: false,
        controls: false,
        dots: true,
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300 && sliderOn === false) {
                if (sliderObj !== 0) {
                    sliderObj.slickGoTo(0, true);
                    sliderObj.slickPlay();
                }
                setSliderOn(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return function cleanup() {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <Container className="gs-block gs-companies-logo">
            <Row className="d-flex justify-content-center">
                <Col md>
                    <h1 className="text-center pb-2 font-weight-bold" data-aos="fade-up">{props.heading}</h1>
                    <p className="text-center pb-2" data-aos="fade-up">
                        {props.desc}
                    </p>
                </Col>
            </Row>
            <Container data-aos="fade-up" className={props.className}>
                <Slider {...settings} ref={(slider) => { setSlider(slider); }}>
                    {logoSections}
                </Slider>
            </Container>
        </Container>
    );
};

export default CompaniesLogoComponent;
