import React from 'react';
import {
    Row, Col, Button, Container, Image,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import {
    HOME_IMAGE, ROUTES, EXTERNAL_LINKS,
} from '../util/constants';

import CompaniesLogoComponent from './CompaniesLogoComponent';

import { logoSections } from './util';

const DescriptionComponent = () => (
    <div>
        <Container className="gs-block ">
            <p className="text-center pb-3 align-middle">
                <span>&nbsp;&nbsp;</span>
            </p>
        </Container>
    </div>
);

const PortfolioComponent = () => {
    const linkOnClickHandler = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className="gs-home-portfolio-section mt-5">
            <div className="gs-portfolio">
                <Row className="justify-content-center gs-portfolio" data-aos="fade-up">
                    <Col>
                        <div>
                            <Container className="gs-block">
                                <h1 className="text-center font-weight-bold b-2 pb-3">
                                    What&nbsp;
                                    <span className="highlight-we-do"> we do</span>
                                </h1>
                                <p className="text-center pb-3 align-middle pl-5 pr-5">
                                    Students today lack support to connect what they learn in the classroom to addressing problems the real world.
                                    We aim to bridge this gap by providing practical, experimental opportunities to spark the next generation to use their
                                    skills in tackling pressing global challenges. Our four major initiatives are:
                                </p>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="justify-content-center pb-5" data-aos="fade-up">
                <div className="gs-portfolio-card1 mb-2 text-center" style={{ position: 'relative', backgroundColor: 'white' }}>
                    <Col xs={7}>
                        <p className="gs-portfolio-header1"> Hack the Globe</p>
                        <p className="gs-portfolio-description1"> The world's first, 2-week multidisciplinary social impact hackathon annually taking place in March.</p>
                        <NavLink to={ROUTES.HACK_THE_GLOBE}>
                            <Button
                                onClick={linkOnClickHandler}
                                className="gs-learn-more-button1"
                            >
                                <span className="gs-portfolio-learn-more1">Learn more</span>
                            </Button>
                        </NavLink>
                    </Col>
                    <Col className="pl-0 pr-0">
                        <Image src={HOME_IMAGE.what_we_do1} fluid />
                    </Col>
                    <Image
                        src={HOME_IMAGE.htg_ellipse}
                        style={{
                            position: 'absolute', bottom: '-40', left: '-40', zIndex: '-1',
                        }}
                    />
                </div>

                <div className="gs-portfolio-card2 ml-5 text-center" style={{ position: 'relative', backgroundColor: 'white' }}>
                    <Col xs={7}>
                        <p className="gs-portfolio-header2"> Curriculum</p>
                        <p className="gs-portfolio-description2"> Creating and implementing case studies on real social issues into university courses by collabing with professors.</p>
                        <NavLink to={ROUTES.CURRICULUM}>
                            <Button
                                onClick={linkOnClickHandler}
                                className="gs-learn-more-button2"
                            >
                                <span className="gs-portfolio-learn-more2">Learn more</span>
                            </Button>
                        </NavLink>
                    </Col>
                    <Col className="pl-0 pr-0">
                        <Image src={HOME_IMAGE.what_we_do2} fluid />
                    </Col>
                    <Image
                        src={HOME_IMAGE.curr_ellipse1}
                        style={{
                            position: 'absolute', top: '-5', left: '-25', zIndex: '-1',
                        }}
                    />
                    <Image
                        src={HOME_IMAGE.curr_ellipse2}
                        style={{
                            position: 'absolute', bottom: '-15', right: '-60', zIndex: '-1',
                        }}
                    />
                </div>
            </Row>
            <Row className="justify-content-center pb-4" data-aos="fade-up">
                <div className="gs-portfolio-card3 mb-2 text-center" style={{ position: 'relative', backgroundColor: 'white' }}>
                    <Col xs={7}>
                        <p className="gs-portfolio-header3"> Social Impact Consulting</p>
                        <p className="gs-portfolio-description3"> Student teams paired with a social impact partner org to help solve real-world issues.</p>
                        <NavLink to={ROUTES.LANDING}>
                            <Button
                                onClick={linkOnClickHandler}
                                className="gs-learn-more-button3"
                            >
                                <span className="gs-portfolio-learn-more3">Learn more</span>
                            </Button>
                        </NavLink>
                    </Col>
                    <Col className="pl-0 pr-0">
                        <Image src={HOME_IMAGE.what_we_do3} fluid />
                    </Col>
                    <Image
                        src={HOME_IMAGE.rect_sic}
                        style={{
                            position: 'absolute', bottom: '-30', left: '-50', zIndex: '-1',
                        }}
                    />
                </div>
                <div className="gs-portfolio-card4 ml-5 text-center" style={{ position: 'relative', backgroundColor: 'white' }}>
                    <Col xs={7}>
                        <p className="gs-portfolio-header4">
                            Thought
                            <br />
                            Leader Panel
                        </p>
                        <p className="gs-portfolio-description4"> Conversations with global experts to inspire students to  tackle major social issues.</p>
                        <NavLink to={ROUTES.PANEL}>
                            <Button
                                onClick={linkOnClickHandler}
                                className="gs-learn-more-button4"
                            >
                                <span className="gs-portfolio-learn-more4">Learn more</span>
                            </Button>
                        </NavLink>
                    </Col>
                    <Col className="pl-0 pr-0">
                        <Image src={HOME_IMAGE.what_we_do4} fluid />
                    </Col>
                    <Image
                        src={HOME_IMAGE.rect_thoughtlp}
                        style={{
                            position: 'absolute', bottom: '-40', right: '-50', zIndex: '-1',
                        }}
                    />
                </div>
            </Row>
        </div>
    );
};

const ObjectivesComponent = () => (
    <div
        className="gs-objectives-block gs-block"
        style={{ backgroundImage: `url(${HOME_IMAGE.objectives_bg})` }}
    >
        <h1 className="pb-5 text-center mt-3 font-weight-bold" data-aos="fade-up">Our Objectives</h1>
        <Container>
            <Row className="mt-2" data-aos="fade-up">
                <Col xs={9} md={5} lg={3} className="mb-4">
                    <div
                        className=""
                        style={{
                            backgroundColor: '#FFF',
                            borderRadius: '30px',
                            padding: '2rem 2rem',
                            textAlign: 'left',
                        }}
                    >
                        <h5 className="font-weight-bold pb-2">
                            <span style={{ color: '#867EE4' }}>01</span>
                            {' '}
                            Inspire
                        </h5>
                        Students across the globe to advance solutions to the world’s most pressing challenges
                    </div>
                </Col>
                <Col xs={12} md="auto" className="gs-objectives-arrow d-flex justify-content-center align-items-center">
                    <Image src={HOME_IMAGE.objectives_arrow} fluid />
                </Col>
                <Col xs={9} md={5} lg={3} className="mb-4">
                    <div
                        className=""
                        style={{
                            backgroundColor: '#FFF',
                            borderRadius: '30px',
                            padding: '2rem 2rem',
                            textAlign: 'left',
                        }}
                    >
                        <h5 className="font-weight-bold pb-2">
                            <span style={{ color: '#867EE4' }}>02</span>
                            {' '}
                            Inspire
                        </h5>
                        Students’ journey by providing accessible up-skilling and experiential application opportunities
                    </div>
                </Col>
                <Col xs={12} md="auto" className="gs-objectives-arrow d-flex justify-content-center align-items-center">
                    <Image src={HOME_IMAGE.objectives_arrow} fluid />
                </Col>
                <Col xs={9} md={5} lg={3} className="mb-4">
                    <div
                        style={{
                            backgroundColor: '#FFF',
                            borderRadius: '30px',
                            padding: '2rem 2rem',
                            textAlign: 'left',
                        }}
                    >
                        <h5 className="font-weight-bold pb-2">
                            <span style={{ color: '#867EE4' }}>03</span>
                            {'  '}
                            Enable
                        </h5>
                        Students to impactful career opportunities across all sectors, including supporting social ventures
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

const ImpactComponent = () => (
    <div className="mb-5">
        <Container fluid className="mt-5">
            <Container className="gs-home-impact-block" data-aos="fade-up">
                <div className="gs-home-impact-text">
                    <h3 className="text-center font-weight-bold pb-5 h1">
                        Our&nbsp;
                        <span className="highlight-impact"> Impact </span>
                        &nbsp;Since 2017
                    </h3>
                    <div className="text-h3">
                        <Row className="justify-content-center pb-2">
                            <div className="impact-card">
                                <div className="gs-description">
                                    <Col xs xl="2">
                                        <Image className="" src={HOME_IMAGE.icon1} />
                                    </Col>
                                </div>
                                <Col>
                                    <p className="pt-2 text-left text-dark-orange impact-header-font-size">
                                        <b>25,000+</b>
                                    </p>
                                    <p className="text-left impact-font-size">
                                        Students engaged
                                    </p>
                                </Col>
                            </div>
                            <div className="impact-card">
                                <div className="gs-description">
                                    <Col xs xl="2">
                                        <Image className="" src={HOME_IMAGE.icon2} />
                                    </Col>
                                </div>
                                <Col>
                                    <p className="pt-2 text-left text-dark-orange impact-header-font-size">
                                        <b>100+</b>
                                    </p>
                                    <p className="text-left impact-font-size">
                                        Partnered Universities
                                    </p>
                                </Col>
                            </div>
                            <div className="impact-card">
                                <div className="gs-description">
                                    <Col>
                                        <Image className="" src={HOME_IMAGE.icon3} />
                                    </Col>
                                </div>
                                <Col>
                                    <p className="pt-2 text-left text-dark-orange impact-header-font-size">
                                        <b>50+</b>
                                    </p>
                                    <p className="text-left impact-font-size">
                                        Countries Reached
                                    </p>
                                </Col>
                            </div>
                        </Row>
                    </div>
                </div>
            </Container>
        </Container>
    </div>
);

const ContactComponent = () => {
    const linkOnClickHandler = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Container className="gs-block gs-home-contact" data-aos="fade-up">
            <p className="text-center pb-3 contact-title" data-aos="fade-up">Eager to Make a Difference?</p>
            <Row className="pb-3">
                <Col md className="pb-3">
                    <Row className="mb-3">
                        <Col>
                            <Image className="gs-contact-image" src={HOME_IMAGE.join_our_team} fluid />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className="pb-3 text-left contact-header" data-aos="fade-up">Join our team</h5>
                            <p className="text-left" data-aos="fade-up">
                                Global Spark is growing rapidly - in the 6 years since our inception, 150+ Global Sparkers (current members and alum) from around the world have engaged over 25,000 students across Canada. If you're a driven and passionate young professional or student, keep an eye out for opportunities!
                            </p>
                        </Col>
                    </Row>
                    <Row className="align-middle">
                        <Col className="text-left" data-aos="fade-up">
                            <NavLink to={ROUTES.TEAM}>
                                <Button
                                    style={{
                                        whiteSpace: 'nowrap', width: '180', marginTop: '5', height: '53', background: '#867EE4',
                                    }}
                                    onClick={linkOnClickHandler}
                                >
                                    <p className="mt-1">View our Team</p>
                                </Button>
                            </NavLink>
                        </Col>
                        <Col sm="7" className="text-left mt-3 mr-4 contact-app">
                            <p data-aos="fade-up"> Next Application Season: Fall 2024</p>
                        </Col>
                    </Row>
                </Col>
                <Col md>
                    <Row className="mb-3">
                        <Col>
                            <Image className="gs-contact-image" src={HOME_IMAGE.partner_with_us} fluid />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className="pb-3 text-left contact-header" data-aos="fade-up">Partner with us</h5>
                            <p className="text-left" data-aos="fade-up">
                                Global Spark is a hub to connect and engage with the brightest and most passionate students across Canada. Whether you're a corporation, startup, NGO, or student organization, we are always looking to continue expanding our partner network, and would love to connect!
                            </p>
                        </Col>
                    </Row>
                    <Row className="align-middle">
                        <Col className="text-left" data-aos="fade-up">
                            <Button
                                style={{
                                    whiteSpace: 'nowrap', width: '145', marginTop: '5', height: '53', background: '#867EE4',
                                }}
                                href={`mailto: ${EXTERNAL_LINKS.EMAIL}`}
                            >
                                <p className="mt-1"> Learn More</p>
                            </Button>
                            <Button
                                className="mr-3"
                                style={{
                                    whiteSpace: 'nowrap', width: '145', marginTop: '5', height: '53', background: '#867EE4',
                                }}
                                href={`mailto: ${EXTERNAL_LINKS.EMAIL}`}
                            >
                                <p className="mt-1"> Contact Us</p>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

const BodyComponent = () => (
    <div id="home-body">
        <ObjectivesComponent />
        <PortfolioComponent />
        <DescriptionComponent />
        <ImpactComponent />
        <CompaniesLogoComponent
            logoSections={logoSections}
            className="home-logos-section"
            heading="Organizations We've Worked With"
            desc="We partner with organizations from around the globe to provide innovative learning opportunities for students."
        />
        <ContactComponent />
        {/* </div> */}
    </div>
);

export default BodyComponent;
