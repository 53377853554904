import { SPONSORS_IMAGE } from '../util/constants';

export const typeStrings = ['leaders', 'ideas', 'solutions'];
export const typeStringColours = ['text-yellow', 'text-blue', 'text-red'];
export const logoSections = [
    [
        SPONSORS_IMAGE.bcg,
        SPONSORS_IMAGE.google,
        SPONSORS_IMAGE.leap,
        SPONSORS_IMAGE.gates,
        SPONSORS_IMAGE.otpp,
        SPONSORS_IMAGE.ibm,
        SPONSORS_IMAGE.wfp,
        SPONSORS_IMAGE.wwf,
        SPONSORS_IMAGE.clearbanc,
        SPONSORS_IMAGE.stc,
        SPONSORS_IMAGE.uoft,
        SPONSORS_IMAGE.uw,
        SPONSORS_IMAGE.western,
        SPONSORS_IMAGE.ubc,
        SPONSORS_IMAGE.mcmaster,
    ],
    [
        SPONSORS_IMAGE.agakhan,
        SPONSORS_IMAGE.gu,
        SPONSORS_IMAGE.uber_eats,
        SPONSORS_IMAGE.cfcf,
        SPONSORS_IMAGE.mars,
        SPONSORS_IMAGE.bmo,
        SPONSORS_IMAGE.td,
        SPONSORS_IMAGE.ewb,
        SPONSORS_IMAGE.munk,
        SPONSORS_IMAGE.yncn,
        SPONSORS_IMAGE.wise,
        SPONSORS_IMAGE.degree_consulting,
    ],
    [
        SPONSORS_IMAGE.sea,
        SPONSORS_IMAGE.ieee,
        SPONSORS_IMAGE.nsbe,
        SPONSORS_IMAGE.blueprint,
        SPONSORS_IMAGE.uweng,
        SPONSORS_IMAGE.uwpm,
        SPONSORS_IMAGE.enactus,
        SPONSORS_IMAGE.bag,
        SPONSORS_IMAGE.wics,
        SPONSORS_IMAGE.sci,
        SPONSORS_IMAGE.uwimpact,
        SPONSORS_IMAGE.uwfossil,
        SPONSORS_IMAGE.uwclimate,
        SPONSORS_IMAGE.pbsn,
        SPONSORS_IMAGE.wecca,
        SPONSORS_IMAGE.wds,
    ],
];
