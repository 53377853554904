import React, { useEffect, useState } from 'react';
import Typed from 'react-typed';
import {
    Row, Col, Image, Container,
} from 'react-bootstrap';

// import CompaniesLogoComponent from './CompaniesLogoComponent';
import { HOME_IMAGE } from '../util/constants';

import { typeStrings, typeStringColours } from './util';
import RotatingGlobe from './RotatingGlobe';
// import Slider from "react-slick";

// NOTE: go over the padding issue (currently fix it by adding 27rem top padding)
// Note: commented out upon Malik's request
// const HpStatCarouselComponent = () => {
//     var slideToShow = 3;
//     var slideToScroll = 3;
//     if (window.innerWidth < 415) {
//         slideToShow = 1
//         slideToScroll = 1
//     }
//     const settings = {
//         infinite: true,
//         speed: 500,
//         slidesToShow: slideToShow,
//         slidesToScroll: slideToScroll,
//         autoplaySpeed: 3000,
//         autoplay: true
//     };

//     return (
//         <div>
//         <Slider {...settings}>
//           <div>
//             <h3><b>11</b></h3>
//             Universities
//           </div>
//           <div>
//             <h3><b>100+</b></h3>
//             Course Partnerships
//           </div>
//           <div>
//             <h3><b>20000+</b></h3>
//             Students Engaged
//           </div>
//           <div>
//             <h3><b>40+</b></h3>
//             Instituitions Reached
//           </div>
//           <div>
//             <h3><b>900+</b></h3>
//             Speaker Panel Registrations
//           </div>
//           <div>
//             <h3><b>21</b></h3>
//             Countries
//           </div>
//         </Slider>
//       </div>
//     );
// };

const LandingComponent = () => {
    const [curStringIndex, setCurStringIndex] = useState(0);
    const [curStringColour, setCurStringColour] = useState(typeStringColours[0]);
    const [rotation, setRotation] = useState(0);
    const [currentPicture, setCurrentPicture] = useState(HOME_IMAGE.landing_p[0]);
    const [typedKey, setTypedKey] = useState(0);
    const pictures = HOME_IMAGE.landing_p;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurStringIndex((prevIndex) => (prevIndex + 1) % typeStrings.length);
            setCurStringColour(typeStringColours[(curStringIndex + 1) % typeStrings.length]);
            setRotation((prevRotation) => prevRotation - 120);
            setCurrentPicture(pictures[(curStringIndex + 1) % pictures.length]);
            setTypedKey((prevKey) => prevKey + 1);
        }, 2000);

        return () => clearInterval(interval);
    }, [curStringIndex, pictures]);

    return (
        <div className="gs-home-bg">
            <div
                className="gs-impact-bg-t bg-light-blue"
                style={{ backgroundImage: `url(${HOME_IMAGE.landing_bg})` }}
            >
                <Container data-aos="fade-up" className="gs-block gs-landing gs-home-landing">
                    <Row className="pt-5">
                        <Col md={6}>
                            <div className="position-absolute design-4" data-aos="fade-up">
                                <p className="mt-5 mr-2 gs-home-sparking-text text-bold text-md">
                                    Sparking
                                    <br />
                                    the next generation
                                    <br />
                                    <span className="of-typed-wrapper">
                                        of
                                        {' '}
                                        <Typed
                                            key={typedKey}
                                            className={curStringColour}
                                            strings={[typeStrings[curStringIndex]]}
                                            typeSpeed={30}
                                            backSpeed={30}
                                            backDelay={1300}
                                            showCursor
                                        />
                                    </span>
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="globe">
                                <RotatingGlobe rotation={rotation} currentPicture={currentPicture} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container data-aos="fade-up" className="gs-block">
                <Row>
                    <Col xs={12} md={3} className="d-flex pt-5 pb-5 justify-content-md-start">
                        <Image className="gs-icon" src={HOME_IMAGE.logo} />
                        <div className="">
                            <p className="gs-home font-weight-bolder">Global </p>
                            <p className="gs-home"> Spark</p>
                        </div>
                    </Col>
                    <Col xs={12} md={9} className="">
                        <div className="text-image-alignment text-gray pl-md-5 pl-lg-0 pt-5 pb-5">
                            <p className="mx-auto">
                                <span style={{ fontWeight: 'bold', paddingTop: '2px', fontSize: '24px' }}>A global educational non-profit organization</span>
                                {' '}
                                <span className="gs-blurb"> that empowers students to be more socially conscious and work towards practical solutions to the world's most pressing challenges.</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LandingComponent;
