import React from 'react';
import {
    Container, Row, Col,
} from 'react-bootstrap';

import PrevPanelistsComponent from './PrevPanelistsComponent';
import CurPanelistsComponent from './CurPanelistsComponent';

const DiscussionComponent = () => (
    <Container className="gs-block">
        <Row className="mt-n5">
            <Col md={5} data-aos="fade-right">
                <h3 className="text-center text-md-right text-dark-blue pb-3">
                    A First-of-its-Kind Discussion
                </h3>
            </Col>
            <Col md={7} data-aos="fade-left">
                <p>
                    Solving the world's most pressing and challenging problems requires creativity, innovation and collaboration.
                    We host a first-of-its-kind discussion by bringing together global thought leaders from NGOs, tech and business
                    to re-think our current approach to solving global problems by leveraging technology. The leaders share their
                    experiences, the promises and challenges of new technologies, and highlight the importance of multidisciplinary
                    thinking and context-specific solutions.
                </p>
            </Col>
        </Row>
    </Container>
);

const QuoteComponent = () => (
    <div className="pt-5">
        <Container className="gs-block bg-dark-blue text-white" fluid>
            <Container>
                <h3 className="text-left pb-3">
                    <i>
                        How do you reach those that are the hardest to reach? The poorest of the poor,
                        the most marginalized. The potential power of AI, Big Data, and other technological enablers, is that it might
                        allow us to level the playing field. Might allow opportunities to be redistributed, distributed more evenly.
                    </i>
                </h3>
                <h5 className="text-right pb-3">
                    <i>—Joseph Wong, Vice President, University of Toronto</i>
                </h5>
            </Container>
        </Container>
    </div>
);

const BodyComponent = () => (
    <div id="ai-panel-body">
        <DiscussionComponent />
        <CurPanelistsComponent />
        <PrevPanelistsComponent />
        <QuoteComponent />
    </div>
);

export default BodyComponent;
