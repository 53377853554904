import React, { useEffect } from 'react';
// import { Container, Row, Form } from 'react-bootstrap';
// import MailchimpSubscribe from 'react-mailchimp-subscribe';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SPONSORS_IMAGE, SPEAKER_IMAGE } from '../util/constants';

import CompaniesLogoComponent from '../home/CompaniesLogoComponent';
import BodyComponent from './BodyComponent';
import './style.css';
import Landing from '../components/Landing';

// const SignupComponent = () => {
//     const [validated, setValidated] = useState(false);
//     const signUpHandler = (event) => {
//         event.preventDefault();
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             event.stopPropagation();
//         }
//         setValidated(true);
//     };

//     return (
//         <Container fluid className="pt-5 pb-5 gs-panel-sign-up gs-sign-up bg-light-yellow">
//             <Form noValidate validated={validated} className="p-0 m-0" onSubmit={signUpHandler}>
//                 <h3 className="text-center">Sign up for updates: </h3>
//                 <Row className="justify-content-center align-items-center text-center">
//                     <div className="mr-auto ml-auto d-flex">
//                         <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />
//                     </div>
//                 </Row>
//             </Form>
//         </Container>
//     );
// };

const PanelComponent = () => {
    useEffect(() => {
        document.title = 'Thought Leader Panel';
    });

    const companiesLogos = [[SPONSORS_IMAGE.bcg, SPONSORS_IMAGE.uoft, SPONSORS_IMAGE.leap]];

    return (
        <div id="ai-panel">
            <Landing image={SPEAKER_IMAGE.landing}>
                <h1 className="pb-2 d-none d-sm-block">
                    An inspiring conversation with global, cross-disciplinary thought leaders
                </h1>
                <h3 className="pb-2 d-sm-none">
                    An inspiring conversation with global, cross-disciplinary thought leaders
                </h3>
                <p>
                    We bring together global experts to discuss how cutting edge technology can be used to tackle our planet’s
                    biggest challenges, and the role of different sectors in implementing sustainable solutions.
                </p>
                <p className="text-yellow">Saturday March 12th, 11am EST</p>
                {/* <a className="btn" href={EXTERNAL_LINKS.PANEL_REG} target="_blank" rel="noopener noreferrer">
                    Register
                    <FontAwesomeIcon icon={faArrowRight} />
                </a> */}
            </Landing>
            {/* <SignupComponent /> */}
            <CompaniesLogoComponent
                logoSections={companiesLogos}
                className="ai-panel-logos-section"
                heading="Our Sponsors"
                desc=""
            />
            <BodyComponent />
        </div>
    );
};

export default PanelComponent;
