import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { SICP_IMAGE } from '../util/constants';

const Landing = ({
    image,
}) => (
    <Container
        data-aos="fade-right"
        fluid
        className="gs-block"
        style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${image})`,
            backgroundSize: '90% 100%', // Adjust the background size
            backgroundRepeat: 'no-repeat', // Prevent repeating the image
            bottom: '20%',
            backgroundPosition: 'center',
        }}
    >
        <Container className="content-inside-landing">
            <Row className="h-100">
                <Col lg={7} className="align-bottom pb-5 pt-4">
                    <h1 className="landing-title pb-4">
                        Social Impact
                        <br />
                        Consulting Program
                    </h1>
                    <p className="landing-description pb-4">
                        Students will be paired with one of our social impact partners to help solve a real-world social impact issue in teams of 4-6 who will engage in workshops and mentorship from professional consultants with experience at leading firms like BCG, McKinsey and Bain.
                    </p>
                    <a className="application-button" href="https://forms.gle/rkFzG8tTrpzaLV196" target="_blank" rel="noopener noreferrer">
                        <p className="application-button-font">Applications Open!</p>
                    </a>
                </Col>
                <Col lg={5} className="d-lg-block pb-4 cartoon-pos">
                    <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <img
                            src={SICP_IMAGE.sicp_intro_cartoon}
                            alt=""
                            className="img-fluid"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    </Container>
);

export default Landing;
