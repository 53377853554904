import React, { useEffect } from 'react';

import CompaniesLogoComponent from '../home/CompaniesLogoComponent';
import BodyComponent from './BodyComponent';

import './style.css';
import { SPONSORS_IMAGE, CURRICULUM_IMAGE } from '../util/constants';
import Landing from '../components/Landing';

const CurriculumComponent = () => {
    useEffect(() => {
        document.title = 'Curriculum';
    });

    const companiesLogos = [[
        SPONSORS_IMAGE.uoft,
        SPONSORS_IMAGE.uw,
        SPONSORS_IMAGE.western,
        SPONSORS_IMAGE.mcmaster,
        SPONSORS_IMAGE.ubc,
        SPONSORS_IMAGE.celo,
    ]];

    return (
        <div id="curriculum">
            <Landing image={CURRICULUM_IMAGE.landing}>
                <h1 className="pb-2">Reshaping university education.</h1>
                <p>
                    Collaborating with professors, institutional leaders and external partners, we develop content that changes
                    the way students learn about and apply multidisciplinary skill sets to global issues.
                </p>
            </Landing>
            <CompaniesLogoComponent
                logoSections={companiesLogos}
                className="curriculum-logos-section"
                heading="Universities and Institutions We’ve Worked With"
                desc="Working directly with international partners, we build opportunities for the exchange of ideas surrounding actual global issues."
            />
            <BodyComponent />
        </div>
    );
};

export default CurriculumComponent;
