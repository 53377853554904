import * as React from 'react';

import {
    Container, Row, Col, Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EXTERNAL_LINKS, SOCIAL_MEDIA_IMAGE, ROUTES } from '../util/constants';

const Footer = () => {
    const onNavigate = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div className="gs-footer pt-5 d-flex align-items-end">
            <Container fluid className="p-0">
                <Row>
                    <Col md className="mt-auto pl-5 mb-5">
                        <Link to={ROUTES.LANDING} className="gs-footer-logo">
                            <Image
                                src={SOCIAL_MEDIA_IMAGE.logo_short}
                                onClick={onNavigate}
                                className="pr-5 pb-4"
                                fluid
                            />
                        </Link>
                        <h5>Global Spark</h5>
                        <p>
                            We are redesigning how students learn about and engage with our
                            world's toughest problems
                        </p>
                        <Row className="gs-social-media pull-left">
                            <a
                                href={EXTERNAL_LINKS.FACEBOOK}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={SOCIAL_MEDIA_IMAGE.facebook_logo_black.default}
                                    alt="facebook_logo_black"
                                />
                            </a>
                            <a
                                href={EXTERNAL_LINKS.INSTAGRAM}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={SOCIAL_MEDIA_IMAGE.instagram_logo_black.default}
                                    alt="instagram_logo_black"
                                />
                            </a>
                            <a
                                href={EXTERNAL_LINKS.LINKEDIN}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={SOCIAL_MEDIA_IMAGE.linkedin_logo_black.default}
                                    alt="linkedin_logo_black"
                                />
                            </a>
                            <a
                                href={EXTERNAL_LINKS.TWITTER}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={SOCIAL_MEDIA_IMAGE.twitter_logo_black.default}
                                    alt="twitter_logo_black"
                                />
                            </a>
                        </Row>
                    </Col>
                    <Col md className="mb-n3 gs-footer-graphics mt-auto">
                        <Image
                            className="w-100"
                            src={SOCIAL_MEDIA_IMAGE.footer_graphics.default}
                            fluid
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
