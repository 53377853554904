import React from 'react';
import { SPEAKER_IMAGE } from '../util/constants';

// Speakers constants
export const speakers2022 = [
    {
        img: SPEAKER_IMAGE.page,
        type: 'SPEAKER',
        name: 'Page Crahan',
        title: <>
            Director at
            {' '}
            <b>Google X</b>
            {' '}
            and Head of Business at
            {' '}
            <b>Tapestry</b>
            {' '}
            {' '}
            {' '}
            (Google's electric grid moonshot)
        </>,
        desc: <>
            Page Crahan is a Director at Google X (Alphabet’s moonshot factory), and leads commercial and go-to-market for Tapestry, X’s
            moonshot for the electric grid. Page is an experienced business leader and start-up veteran who has created go-to-market plans
            for early/growth-stage companies, two of which reached unicorn status: Sunrun in rooftop solar energy in the U.S., and Konfio
            in financial services in Mexico. She is a co-inventor of several U.S. Patents including Interactive Design for Solar Design;
            Electrical Power Grid Interconnections; Power Delivery System Visualizer; Integrated Scenario Modeling for Electric Grid Decisions
            (pending). Page has been recognized as a top 50 Climate Tech Operators in the 2021 Climate Draft.
            <br />
            <br />
            Outside of X, Page is an advisor and angel investor with her firm Persistent Ventures, an expert at Powerhouse Ventures, teaches
            at the Stanford Graduate School of Business and Rippleworks, and coaches U.S. State Department’s Emerging Leadership TechWomen leaders.
        </>,
    },
    {
        img: SPEAKER_IMAGE.matt,
        type: 'SPEAKER',
        name: 'Matt Eggers',
        title: <>
            Investor at
            {' '}
            <b>Breakthrough Energy Ventures</b>
            ,
            <br />
            Former VP at
            {' '}
            <b>Tesla</b>
        </>,
        desc: <>
            Matt is an investor at Breakthrough Energy Ventures (BEV), the $2B climatetech venture capital started by Bill Gates. His combined
            BEV and angel portfolio includes WeaveGrid, Zymergen, Omnidian, and many others. He also serves on the board of 75F, ZeroAvia,
            Aeroseal, LuxWall, and Posigen, and is a co-founder of Rooted Relationships.
            <br />
            <br />
            Matt was previously VP, North American Sales at Tesla and during his tenure, vehicle sales increased faster than at any point in
            their history. Before joining Tesla, Matt was VP, Operations and Software Product Management at Sunrun Inc, leading operations and
            software product functions as Sunrun grew from 30 to 1,200 employees and 2,000 to 55,000 residential solar customers. Before joining BEV,
            he was GM of energy and smart buildings software at Yardi Systems, the largest SaaS company serving the global real estate sector. He
            has held management roles at Bloom Energy and Genentech. Matt has a B.S. in molecular biology from Duke University and an MBA from Stanford.
        </>,
    },
    {
        img: SPEAKER_IMAGE.donnel,
        type: 'SPEAKER',
        name: 'Donnel Baird',
        title: <>
            CEO &amp; Founder of
            {' '}
            <b>BlocPower</b>
            ,
            <br />
            <b>CNBC Top 50</b>
            {' '}
            Disruptor
        </>,
        desc: <>
            Donnel Baird is the Founder and CEO of BlocPower, a clean tech startup based in New York City. The startup, which was named a CNBC Top 50
            Disruptor in 2021, has raised over $100M in funding from backers including the Bezos Earth Fund, the Microsoft Climate Innovation Fund, Kapor
            Capital, Andreessen Horowitz, and Eric and Wendy Schmidt, and Salesforce. BlocPower develops portfolios of clean energy retrofit opportunities
            in underserved communities and connects those opportunities to investors seeking social, environmental, and financial returns. BlocPower creates
            jobs for qualified local low-income workers, energy savings for community institutions, reduces carbon emissions, and provides returns to investors.
            <br />
            <br />
            Baird is a graduate of Duke University and Columbia Business School. He spent four years as a political and community organizer, and over two
            years managing a national initiative to leverage American Reinvestment and Recovery Act energy efficiency investments in underserved communities.
            He sits on various boards including the New York Federal Reserve Bank.
        </>,
    },
    {
        img: SPEAKER_IMAGE.tina,
        type: 'MODERATOR',
        name: 'Tina Zuzek-Arden',
        title: <>
            Managing Director &amp; Partner at
            {' '}
            <b>BCG</b>
            ,
            {' '}
            Global Energy &amp; Climate Expert
        </>,
        desc: <>
            Tina Zuzek-Arden is a member of the Energy and Public Sector practices at Boston Consulting Group. Her focus is strategy as it relates to climate change
            responses, especially when based on sustainability, renewables, and demand-side management. She leads demand-side management in North America for
            the firm as well as co-leads BCG’s work with the US Federal Government on Energy and Climate Change.
            <br />
            <br />
            Tina has experience in driving greater infrastructure and power access in sub-Saharan Africa and South Asia, impacting energy accessibility at the
            global scale. She works with public utilities and others on energy grid decarbonization and general carbon-reduction strategies across a wide range of
            solutions, including distributed energy resources, grid-scale renewables, and electrification. As a graduate of Harvard Business School, she now works with
            public sector clients on defining baselines, adaptation, resiliency, emissions reduction plans, and broad scale transformations of procurement and operations.
        </>,
    },
];

export const speakers2021 = [
    {
        img: SPEAKER_IMAGE.andrew,
        type: 'SPEAKER',
        name: 'Dr. Andrew Trister',
        title: <>
            Head of Digital Health Innovation,
            {' '}
            <b>The Bill and Melinda Gates Foundation</b>
            <br />
            Former Head of Machine Learning and Research for Health Special Projects,
            {' '}
            <b>Apple</b>
        </>,
        desc: <>
            Andrew Trister leads digital health innovation at Bill &amp; Melinda Gates Foundation. His team’s focus is to leverage
            integrated mobile technology and data systems to aid in the appropriate use and process of performing diagnostics as
            well as to enable high quality healthcare and wellness solutions in resource limited settings. The team leverages advanced
            technologies and data science built around mobile devices as an interface to advance the goals of the foundation through
            internal and external partnerships.
            <br />
            <br />
            Andrew is a physician scientist passionate about leveraging technology to improve health care for all. He believes the
            combination of multiscale digital data from patients can impact our understanding, prevention and treatment of disease.
            He joined the foundation from Apple, where he led clinical research and machine learning efforts in health for the special
            projects team. Prior to Apple, he was the senior physician at Sage Bionetworks, where he focused on incentives for
            researchers and participants to collaborate on large datasets and translating those findings to clinical settings.
            <br />
            <br />
            Andrew completed residency in radiation oncology at the University of Washington, earned an M.D., Ph.D. in bioengineering,
            MSE and BSE in computer science, and a B.A. in biological basis of behavior all from the University of Pennsylvania.
        </>,
    },
    {
        img: SPEAKER_IMAGE.jim,
        type: 'SPEAKER',
        name: 'Jim Larson',
        title: <>
            Managing Director and Partner,
            {' '}
            <b>BCG</b>
            <br />
            Global Health and Social Impact Expert
        </>,
        desc: <>
            Jim Larson is a Managing Director and Partner at BCG, and is a core member of BCG’s Health Care and Social Impact practices.
            Jim also leads the firm’s partnership with the Bill and Melinda Gates Foundation. Jim has spent time in BCG’s Washington DC,
            Mumbai, and Seattle offices, primarily working in health care and global public health. He has also led a number of related
            efforts engaging the private sector on global health topics, and is particularly active in the driving impact of digital in
            global health. In his role leading the firm’s work with the Gates Foundation, Jim supports strategy development and execution
            for the foundation and their grantees. He has worked with the foundation for more than ten years, and has contributed to more
            than 60 projects for nearly all of their global program teams. Before joining BCG, Jim focused on economic and health policy
            at Harvard University.
        </>,
    },
    {
        img: SPEAKER_IMAGE.maureen,
        type: 'SPEAKER',
        name: 'Dr. Maureen Lewis ',
        title: <>
            CEO &amp; Founder,
            {' '}
            <b>Aceso Global</b>
            <br />
            Former Chief Economist,
            {' '}
            <b>The World Bank</b>
        </>,
        desc: <>
            Maureen Lewis has spent over 25 years advising government and private sector leaders around the world on health system reforms.
            Dr. Lewis has held a variety of management and leadership positions in global health, including Chief Economist for Human Development
            at the World Bank. She is the author of more than 75 research publications and working papers and five books, and is a member of
            numerous international organizations including the Council on Foreign Relations  Dr. Lewis speaks frequently for private, public
            and nonprofit audiences across the globe. She has a PhD in Economics from Johns Hopkins University.
        </>,
    },
    {
        img: SPEAKER_IMAGE.natasha,
        type: 'MODERATOR',
        name: 'Natasha Walji',
        title: <>
            Director of Telecom, Tech and Public Sector,
            {' '}
            <b>Google Canada</b>
            <br />
            Recognized as one of Canada’s
            {' '}
            <b>Top 100 Most Powerful Women</b>
            {' '}
            by WXN
        </>,
        desc: <>
            Natasha Walji is a Director at Google Canada leading the Telecom, Tech and Public Sector industries. Previously, she was a McKinsey
            consultant and a software developer. Natasha has actively served the community for ~20+ years and currently serves on the Board of
            Ronald McDonald House Canada. She holds a BSc, Computer Science (UVic), MBA (Yale) and MSt. Sustainability Leadership (Cambridge).
            Natasha is one of Canada's Top 100 Most Powerful Women (WXN).
        </>,
    },
];

export const speakers2020 = [
    {
        title: <>
            Senior Partner &amp; Senior Advisor,
            {' '}
            <b>BCG</b>
        </>,
        name: 'Sarah Cairns-Smith',
        desc: 'Sarah Cairns-Smith is a Senior Advisor for the Boston Consulting Group, she also acts as an independent advisor to foundations and non-profit organizations. In 2019 Sarah was a fellow in Harvard’s Advanced Leadership Initiative. She was formerly a Senior Partner and Managing Director of The Boston Consulting Group, retiring in June 2018. Sarah was a member of BCG’s global social impact, health care and people and organization leadership teams. She led BCGs Scientist Network, a global team of over 500 PhD and MDs. Sarah has led a variety of strategic and organizational assignments in the public, private and social sectors across the globe. She has experience driving multiple foundation efforts on topics as diverse as social justice/human rights, global health and development and climate change. Clients included the Bill & Melinda Gates Foundation, Ford Foundation and the United Nations. She has also worked with the private sector specializing in innovation and R&D with a focus in biopharma. She also helped form an industry/NIH consortium – the Accelerating Medicines Partnership.Her TED talk covered perspectives on digital innovation and global development: https://www.ted.com/watch/ted-institute/ted-bcg/sarah-cairns-smith-finally-tech-solutions-to-economic-development. In 2019, Sarah was a fellow in Harvard’s Advanced Leadership Initiative.Sarah chairs the board of the Amyloidosis Research Consortium and is on the board of Engender Health. Sarah received her PhD in Biochemistry from the Imperial Cancer Research Fund, London and did post-doctoral research at the Center for Neurobiology, Columbia University. Sarah completed the MBA program at Columbia Business School (Distinction).',
        img: SPEAKER_IMAGE.sarah,
        type: 'SPEAKER',
    },
    {
        title: <>
            SVP,
            {' '}
            <b>ElementAI</b>
        </>,
        name: 'Omar Dhalla',
        desc: 'As SVP Solutions, Strategy and Corporate Development, Omar is focused on delivering value to enterprise partners while establishing the companys global market presence. At Real Ventures, he established the fund’s Ontario ecosystem operations and led deals in artificial intelligence, internet-of-things, and financial technology. Omar started his career with Accenture and McKinsey, then founded and operated early-stage ventures in both the packaged goods and media industries. He holds an MBA from the Richard Ivey School of Business at Western Ontario and a B.Comm (Finance) from the University of British Columbia.',
        img: SPEAKER_IMAGE.omar,
        type: 'SPEAKER',
    },
    {
        title: <>Fintech and Digital Economy Innovation Expert</>,
        name: 'Mark Wensley',
        desc: 'Mark Wensley has over 15 years experience at the intersection of finance, technology and impact in emerging markets. He is currently a consultant to a major donor on the development of their fintech strategy for Africa and an adviser to several seed-stage fintech companies. He previously built and led the growth of the Mastercard Foundation’s fintech initiative and managed its smallholder farmer finance portfolio, which collectively deployed $300 million to serve over 12 million people in Africa. These initiatives address the critical financial needs of low-income clients and small businesses. He previously sourced startup finance companies across Asia for Unitus, a pioneering impact investor that accelerated their growth with equity investments and advisory. He is energized by building mission-driven organizations that tackle some of the world’s biggest challenges. He holds a Masters in Economics of Development from the Australian National University and a Bachelor of Commerce from McGill University.',
        img: SPEAKER_IMAGE.mark,
        type: 'SPEAKER',
    },
    {
        title: <>
            Professor of Innovation,
            {' '}
            <b>Munk School of Global Affairs &amp; Public Policy</b>
        </>,
        name: 'Joseph Wong',
        desc: 'Joseph Wong is the Ralph and Roz Halbert Professor of Innovation at the Munk School of Global Affairs & Public Policy, and Professor of Political Science. He was the Director of the Asian Institute at the Munk School from 2005 to 2014. Wong is the author of many academic articles and several books, including Healthy Democracies: Welfare Politics In Taiwan and South Korea and Betting on Biotech: Innovation and the Limits of Asia’s Developmental State, both published by Cornell University Press. He is the co-editor, with Edward Friedman, of Political Transitions in Dominant Party Systems: Learning to Lose, published by Routledge, and Wong recently co-edited with Dilip Soman and Janice Stein Innovating for the Global South with the University of Toronto Press. Wong’s articles have appeared in journals such as Perspectives on Politics, Comparative Political Studies, Politics and Society, Governance, among many others. Professor Wong has been a visiting scholar at institutions in the US (Harvard), Taiwan, Korea, and the UK (Oxford); has worked extensively with the World Bank and the UN; and has advised governments on matters of public policy in Asia, Africa, the Americas, and Europe. Wong’s current research focuses on poverty and innovation. He is also working with Professor Dan Slater (Chicago) on a book about Asia’s development and democracy, currently under contract with Princeton University Press. Professor Wong teaches courses in the department of Political Science, the Munk One program and the Munk School of Global Affairs & Public Policy. Wong was educated at McGill and the University of Wisconsin-Madison.',
        img: SPEAKER_IMAGE.joe,
        type: 'SPEAKER',
    },
    {
        title: <>
            Associate Director,
            {' '}
            <b>U of T School of Cities</b>
        </>,
        name: 'Marieme Lo',
        desc: 'Professor Marieme Lo is the Director of the African Studies Program and Associate Professor in Women and Gender Studies. She has a Licence from Paris 1, Panthéon-Sorbonne, a MA from the University of Dakar (Senegal) and MSc and PhD from Cornell University. She has also held fellowships at the University of Oxford and Georgetown University. Professor Lo’s work encompasses the political economy and creative dynamism of African urban informal economies, migration studies, female entrepreneurship, economic justice and rights to livelihood, with a focus on the dynamics of urban transformation, urban governance, resilience, urban poverty and inclusive urbanism, particularly in post-colonial cities. Her SSHRC funded research project, ‘Entrepreneurial Nomadism’, explores and maps women entrepreneurs’ transnational circuits and trade mobility in cities across three continents. Professor Lo is also engaged in collaboration with grassroots women’s organizations, civil society networks such as the West Africa Civil Society, and international organizations such as UN-Women, the World Food Programme, and the United Nations Development Programme (UNDP).',
        img: SPEAKER_IMAGE.marieme,
        type: 'MODERATOR',
    },
];

export const speakers2019 = [
    {
        title: <>
            Co-founder and Program Lead of AI for Social Good,
            {' '}
            <b>Google</b>
        </>,
        name: 'Carla Bromberg',
        desc: '',
        img: SPEAKER_IMAGE.carla,
        type: 'SPEAKER',
    },
    {
        title: <>
            AI &amp; Global Health Expert,
            {' '}
            <b>BCG</b>
            <br />
            Former Advisor,
            {' '}
            <b>US Mission to the UN</b>
        </>,
        name: 'Jessie Duncan',
        desc: '',
        img: SPEAKER_IMAGE.jessie,
        type: 'SPEAKER',
    },
    {
        title: <>
            Head of IDAS,
            {' '}
            <b>KPMG</b>
            <br />
            Former Director,
            {' '}
            <b>AICS</b>
            <br />
            Former Director of Strategy &amp; Operations,
            {' '}
            <b>The World Bank</b>
        </>,
        name: 'Laura Frigenti',
        desc: '',
        img: SPEAKER_IMAGE.laura,
        type: 'SPEAKER',
    },
    {
        title: <>
            Founding Director,
            {' '}
            <b>Munk School of Public Policy</b>
            <br />
            Member of the
            {' '}
            <b>Order of Canada</b>
        </>,
        name: 'Janice Stein',
        desc: '',
        img: SPEAKER_IMAGE.janice,
        type: 'SPEAKER',
    },
    {
        title: <>
            Professor &amp; Canada Research Chair in Novel Optimization &amp; Analytics in Health,
            {' '}
            <b>University of Toronto</b>
        </>,
        name: 'Timothy Chan',
        desc: '',
        img: SPEAKER_IMAGE.tim,
        type: 'MODERATOR',
    },
];
