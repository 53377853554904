import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import SanitizedHTML from 'react-sanitized-html';
import { useParams } from 'react-router-dom';
import { reportPageView } from '../util/analytics';
import { PODCAST_IMAGE } from '../util/constants';
import { EPISODES } from './episodes';
import './style.css';

const PodcastShowNotesComponent = () => {
    const { episodeKey } = useParams();
    const [currentEpisode, setCurrentEpisode] = useState();

    useEffect(() => {
        reportPageView();
        document.title = 'Podcast Episode';
        setCurrentEpisode(EPISODES[episodeKey]);
    }, [episodeKey]);

    return (
        currentEpisode
            ? (
                <div>
                    <PodcastShowNotes episode={currentEpisode} />
                </div>
            )
            : null
    );
};

const PodcastShowNotes = ({ episode }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div>
            <Container className="gs-block pb-3">
                <div className="row">
                    <div className="col-sm-8">
                        <h1 className="pb-2">
                            {episode.title}
                        </h1>
                        <p className="pb-2">
                            {episode.description}
                        </p>
                    </div>
                    <div
                        className="col-sm-4 podcast-header-graphics"
                        style={{
                            backgroundImage: `url(${PODCAST_IMAGE.podcast_logo_round})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center',
                        }}
                    />
                </div>

                <div className="row py-5">
                    <div className="col-sm-9">
                        <div>
                            <iframe title={episode.title} src={episode.spotifyEmbed} width="100%" height="152" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                        </div>
                        <div className="pt-5 px-5">
                            {episode.showNotes.map((text, i) => (
                                <SanitizedHTML
                                    key={i}
                                    className="py-2"
                                    allowedAttributes={{ strong: ['class'] }}
                                    allowedTags={['strong', 'br']}
                                    html={text}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="col-sm-1" />
                    <div className="col-sm-2 py-3">
                        <h5> Listen Here: </h5>
                        {
                            episode.AppleLink
                            && (
                                <div className="py-2 pt-4 d-flex justify-content-center">
                                    <a href={episode.AppleLink} rel="noopener noreferrer" target="_blank" className="bg-light p-3 rounded"><img src={PODCAST_IMAGE.apple_podcast} width="50" title="Apple Podcast" alt="Apple Podcast" /></a>
                                </div>
                            )
                        }
                        {
                            episode.GoogleLink
                            && (
                                <div className="py-2 d-flex justify-content-center">
                                    <a href={episode.GoogleLink} rel="noopener noreferrer" target="_blank" className="bg-light p-3 rounded"><img src={PODCAST_IMAGE.google_podcast} width="50" title="Google Podcast" alt="Google Podcast" /></a>
                                </div>
                            )
                        }
                        {
                            episode.YouTubeLink
                            && (
                                <div className="py-2 d-flex justify-content-center">
                                    <a href={episode.YouTubeLink} rel="noopener noreferrer" target="_blank" className="bg-light p-3 rounded"><img src={PODCAST_IMAGE.youtube_podcast} width="50" title="YouTube Podcast" alt="YouTube Podcast" /></a>
                                </div>
                            )
                        }
                        {
                            episode.SpotifyLink
                            && (
                                <div className="py-2 d-flex justify-content-center">
                                    <a href={episode.SpotifyLink} rel="noopener noreferrer" target="_blank" className="bg-light p-3 rounded"><img src={PODCAST_IMAGE.spotify_podcast} width="50" title="Spotify Podcast" alt="Spotify Podcast" /></a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default PodcastShowNotesComponent;
