import React, { useEffect } from 'react';
import { reportPageView } from '../util/analytics';
import PodcastHeader from './PodcastHeader';
import PodcastEpisodes from './PodcastEpisodes';
import './style.css';

const PodcastComponent = () => {
    useEffect(() => {
        reportPageView();
        document.title = 'Podcast';
    }, []);

    return (
        <div>
            <PodcastHeader />
            <PodcastEpisodes />
        </div>
    );
};

export default PodcastComponent;
