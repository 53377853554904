import React from 'react';
import { Container } from 'react-bootstrap';
import { PODCAST_IMAGE } from '../util/constants';

const PodcastHeader = () => (
    <Container className="gs-block pb-3">
        <div className="row">
            <div className="col-sm-4">
                <h1 className="pb-2">
                    Global Sparks Podcast
                </h1>
                <p className="pb-2">
                    Welcome to CC:World, a podcast by Global Spark where we engage in conversations with global experts about cutting-edge solutions to our world's most pressing problems using interdisciplinary problem solving, creativity, and collaboration, and how they've merged their careers with social impact work.
                </p>
            </div>
            <div className="col-sm-1" />
            <div
                className="col-sm-7 podcast-header-graphics rounded"
                style={{
                    background: `url(${PODCAST_IMAGE.podcast_logo_round}), rgba(51,51,51,255)`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain, cover', backgroundPosition: 'center',
                }}
            />
        </div>
    </Container>
);
export default PodcastHeader;
