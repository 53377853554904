import React from 'react';
import {
    Col, Row, Container, Image,
} from 'react-bootstrap';
import { SICP_IMAGE } from '../util/constants';

const TimelineComponent = () => (
    <div>
        <Container className="gs-block timeline-bg" fluid>
            <Row className="pb-5" data-aos="fade-up">
                <Col md={{ span: 8, offset: 2 }}>
                    <h3 className="pb-3 timeline-heading">Get Ready for SICP 2024</h3>
                </Col>
            </Row>
            <Row data-aos="fade-up" className="cards-top">
                {/* added a vertical line as needed for mobile view */}
                <div className="vertical-line d-md-block d-lg-none" />
                <Col lg={{ span: 3 }} md={12} className="timeline-card">
                    <p className="timeline-card-date">MARCH 20TH</p>
                    <p className="timeline-card-name">Applications Open</p>
                    <p className="timeline-card-para">Acceptances are sent on a rolling basis</p>
                </Col>
                <Col lg={{ span: 3 }} md={12} className="timeline-card second-card">
                    <p className="timeline-card-date">MAY 15TH</p>
                    <p className="timeline-card-name">Opening Ceremony</p>
                    <p className="timeline-card-para">In-person Toronto Social Event</p>
                </Col>
            </Row>
            <Image src={SICP_IMAGE.timeline_bar} className="timeline-bar pt-5 pb-5 d-none d-lg-block" />
            <Row data-aos="fade-up" className="cards-top2">
                <Col lg={{ span: 3 }} md={12} className="timeline-card">
                    <p className="timeline-card-date">APRIL 6TH</p>
                    <p className="timeline-card-name">Interview Selection</p>
                    <p className="timeline-card-para">One round of virtual interviews</p>
                </Col>
                <Col lg={{ span: 3 }} md={12} className="timeline-card second-card2">
                    <p className="timeline-card-date">JULY 24TH</p>
                    <p className="timeline-card-name">Final Presentation</p>
                    <p className="timeline-card-para">Presentation and Feedback from clients</p>
                </Col>
            </Row>
        </Container>
    </div>
);

export default TimelineComponent;
