import React from 'react';
import {
    Col, Row, Container,
} from 'react-bootstrap';

const ProjectScopeComponent = () => (
    <div className="gs-block">
        <Container className="gs-block bg-white" fluid>
            <Row className="pt-5 justify-content-center align-items-center" data-aos="fade-up">
                <Col md={4} xs={12} className="mb-auto">
                    <h3 className="scope-heading pb-5">Last year’s project scope</h3>
                    <ul className="scope-list">
                        <li className="pb-4">
                            Optimizing internal value-chain to improve resource allocation bolstering program delivery
                        </li>
                        <li className="pb-4">
                            Increase program targeting, retention, and satisfaction
                        </li>
                        <li>
                            Streamline external relations to marginalized communities
                        </li>
                    </ul>
                </Col>
                <Col md={{ span: 4, offset: 1 }} xs={12} className="mb-auto">
                    <h3 className="scope-heading pb-5">This year’s project scope</h3>
                    <ul className="scope-coming-soon">
                        <li className="">
                            Coming Soon!
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </div>
);

export default ProjectScopeComponent;
