import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import BodyComponent from './BodyComponent';
import CompaniesLogoComponent from '../home/CompaniesLogoComponent';

import './style.css';
import { SPONSORS_IMAGE, HTG_IMAGE, EXTERNAL_LINKS } from '../util/constants';
import Landing from '../components/Landing';

import { isDuringActivePeriod } from '../util/isDuringActivePeriod';

const HackTheGlobeComponent = () => {
    const [isApplicationOpen, setIsApplicationOpen] = useState(false);

    useEffect(() => {
        const dateTimeNow = new Date().getTime();

        document.title = 'Hack the Globe';

        setIsApplicationOpen(isDuringActivePeriod('APPLICATION_PERIOD', dateTimeNow));
    }, []);

    const logoSections = [
        [
            SPONSORS_IMAGE.bcg,
            SPONSORS_IMAGE.google,
            SPONSORS_IMAGE.jaj,
            SPONSORS_IMAGE.leap,
            SPONSORS_IMAGE.cfcf,
            SPONSORS_IMAGE.gates,
            SPONSORS_IMAGE.stc,
            SPONSORS_IMAGE.otpp,
            SPONSORS_IMAGE.ibm,
            SPONSORS_IMAGE.clearbanc,
            SPONSORS_IMAGE.loi,
            SPONSORS_IMAGE.ef,
        ],
        [
            SPONSORS_IMAGE.darwin,
            SPONSORS_IMAGE.agakhan,
            SPONSORS_IMAGE.soc,
            SPONSORS_IMAGE.gcc,
            SPONSORS_IMAGE.bcgcfca,
            SPONSORS_IMAGE.mastercard,
            SPONSORS_IMAGE.grameen,
            SPONSORS_IMAGE.mars,
            SPONSORS_IMAGE.voiceflow,
            SPONSORS_IMAGE.wwf,
        ],
        [
            SPONSORS_IMAGE.cyclica,
            SPONSORS_IMAGE.flipp,
            SPONSORS_IMAGE.ripple,
            SPONSORS_IMAGE.thinkdata,
            SPONSORS_IMAGE.united,
            SPONSORS_IMAGE.rumie,
            SPONSORS_IMAGE.global_medic,
            SPONSORS_IMAGE.nem,
            SPONSORS_IMAGE.bcch,
            SPONSORS_IMAGE.td,
            SPONSORS_IMAGE.bmo,
        ],
    ];

    return (
        <div id="htg">
            <Landing image={HTG_IMAGE.landing}>
                <h1 className="pb-2">
                    The world&apos;s first multidisciplinary social impact hackathon.
                </h1>
                <p>
                    Tackle the world's most pressing development challenges
                    <br />
                    Kickoff: March 2nd, 2024
                    <br />
                    Ideation: March 3rd-14th, 2024
                    <br />
                    Hackathon: March 15-17th, 2024
                    <br />
                </p>
                { isApplicationOpen
                && (
                    <a className="btn mb-3" href={EXTERNAL_LINKS.HACKER_DASH} target="_blank" rel="noopener noreferrer">
                        Apply
                        <FontAwesomeIcon icon={faArrowRight} />
                    </a>
                )}
            </Landing>
            <CompaniesLogoComponent
                logoSections={logoSections}
                className="htg-logos-section"
                heading="Organizations We’ve Worked With"
                desc=""
            />
            <BodyComponent />
        </div>
    );
};

export default HackTheGlobeComponent;
