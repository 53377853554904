import React from 'react';
import { Container } from 'react-bootstrap';
import { EPISODES } from './episodes';

const PodcastEpisodes = () => (
    <div>
        <Container className="gs-block">
            <div className="pb-3">
                <h2>Latest Episode</h2>
                <PodcastEpisodesContainter episode={EPISODES[Object.keys(EPISODES)[Object.keys(EPISODES).length - 1]]} />
            </div>

            <div className="py-3">
                <h2>Other Episodes</h2>
                <div className="row py-2">
                    {Object.keys(EPISODES).map((episodeKey, i) => {
                        if (i === Object.keys(EPISODES).length - 1) {
                            return (
                                <div key="n/a" />
                            );
                        }
                        return (
                            <div className="col-sm-4" key={episodeKey}>
                                <PodcastEpisodesContainter episode={EPISODES[episodeKey]} small />
                            </div>
                        );
                    })}
                </div>
            </div>
        </Container>
    </div>
);

const PodcastEpisodesContainter = ({ episode, small }) => (
    <div className="podcast-episode-container ">
        <h5>
            {' '}
            <a href={`#/podcast/${episode.id}`} className="text-black">
                {' '}
                {episode.title}
                {' '}
            </a>
        </h5>
        {small
            ? <iframe title={episode.title} src={episode.spotifyEmbed} width="100%" height="152" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
            : (
                <div className="row pt-2">
                    <div className="col-sm-6">
                        <p>
                            {episode.description}
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <iframe title={episode.title} src={episode.spotifyEmbed} width="100%" height="152" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                    </div>
                </div>
            )}
    </div>
);
export default PodcastEpisodes;
