import React, { useEffect } from 'react';

import { Container, Image } from 'react-bootstrap';
import { COMMUNITY_IMAGE } from '../util/constants';
import '../assets/style/style.css';

const CommunityComponent = () => {
    useEffect(() => {
        document.title = 'Join Our Community';
    });

    return (
        <div>
            <Container className="d-flex flex-column justify-content-center align-items-center mt-5">
                <h1>Join Our Community</h1>
                <p className="my-5">Coming soon . . .</p>
                <Image
                    src={COMMUNITY_IMAGE.community_roundtable.default}
                    width={500}
                    alt="community"
                    fluid
                />
            </Container>
        </div>
    );
};

export default CommunityComponent;
