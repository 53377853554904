import React, { useEffect } from 'react';
import { reportPageView } from '../util/analytics';

import BodyComponent from './BodyComponent';
import CompaniesLogoComponent from '../home/CompaniesLogoComponent';
import { SPONSORS_IMAGE, EVENTS_IMAGE } from '../util/constants';
import Landing from '../components/Landing';

import './style.css';

const LocalEventsComponent = () => {
    useEffect(() => {
        reportPageView();
        document.title = 'Local Events';
    });

    const logoSections = [
        [
            SPONSORS_IMAGE.munk, SPONSORS_IMAGE.yncn, SPONSORS_IMAGE.wise, SPONSORS_IMAGE.reach_alliance,
            SPONSORS_IMAGE.ieee, SPONSORS_IMAGE.sea, SPONSORS_IMAGE.eia, SPONSORS_IMAGE.science_rendezvous,
            SPONSORS_IMAGE.hh_debates, SPONSORS_IMAGE.nsbe, SPONSORS_IMAGE.degree_consulting,
        ],
        [
            SPONSORS_IMAGE.uwux, SPONSORS_IMAGE.sdg, SPONSORS_IMAGE.impact_alliance, SPONSORS_IMAGE.uwpm, SPONSORS_IMAGE.wics,
            SPONSORS_IMAGE.blueprint, SPONSORS_IMAGE.uweng, SPONSORS_IMAGE.food_kid, SPONSORS_IMAGE.ic3, SPONSORS_IMAGE.uw_wise,
        ],
        [
            SPONSORS_IMAGE.enactus, SPONSORS_IMAGE.enactus_hack, SPONSORS_IMAGE.ewb, SPONSORS_IMAGE.uwo_debate,
            SPONSORS_IMAGE.pbsn, SPONSORS_IMAGE.wecca,
        ],
    ];

    return (
        <div id="local-events">
            <Landing image={EVENTS_IMAGE.landing}>
                <h1 className="pb-2 d-none d-sm-block">
                    Impactful events to foster the next generation of successful global problem solvers
                </h1>
                <h3 className="pb-2 d-sm-none">
                    Impactful events to foster the next generation of successful global problem solvers
                </h3>
                <p>
                    Partnering with student organizations and institutions, we organize hands-on learning opportunities
                    and events to change how students approach tackling global challenges.
                </p>
            </Landing>
            <CompaniesLogoComponent
                logoSections={logoSections}
                className="local-events-logos-section"
                heading="Organizations We’re Partnered With"
                desc="We partner with student organizations and university faculties across Canada to provide hands-on learning opportunities for students."
            />
            <BodyComponent />
        </div>
    );
};

export default LocalEventsComponent;
