import React from 'react';
import {
    Col, Row, Container,
} from 'react-bootstrap';

const WhatComponent = () => (
    <Row className="pt-5 justify-content-center align-items-center" data-aos="fade-up">
        <Col md={5} xs={12} className="mb-auto">
            <p className="what-paragraph">
                The social impact consulting program is a pro bono program where businesses can signup to receive pro-bono work from&nbsp;
                <span className="highlight-sicp">
                    <span className="bold-text"> bright minds </span>
                    from&nbsp;
                </span>
                <span className="highlight-sicp">
                    <span className="bold-text"> around the world. </span>
                </span>
            </p>
        </Col>
        <Col md={{ span: 5, offset: 1 }} xs={12} className="mb-auto">
            <p className="what-paragraph">
                The program will be multidisciplinary in nature and will emphasize the importance of understanding the
                <span className="bold-text"> cultural, economic, social, </span>
                and
                <span className="bold-text"> political </span>
                nuances when problem solving.
            </p>
        </Col>
    </Row>
);

const WhatIsSICP = () => (
    <div className="pt-5 pb-5">
        <Container fluid className="gs-block bg-white">
            <Container>
                <h3 className="sicp-heading" data-aos="fade-up">What is SICP?</h3>
                <WhatComponent />
            </Container>
        </Container>
    </div>
);

export default WhatIsSICP;
