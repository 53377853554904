import React, { useState } from 'react';

import {
    Tabs, Tab, Modal, Container, Button,
} from 'react-bootstrap';
import Slider from 'react-slick';

import {
    UofT, UofW, UWO, colours, settings, settingsLg,
} from './util';

export const ScheduleTabEventComponent = (props) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div data-aos="fade-up">
            <div className="gs-schedule-tab-event-slide d-flex align-items-end flex-column" style={{ borderTop: `20px solid #${props.colour}` }}>
                <div>
                    <img style={{ width: '18rem' }} src={props.event.banner} alt="banner" />
                    <p className="pb-2">{ props.event.location }</p>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <h5 onClick={() => setShowModal(true)} className="hover-underline">
                        { props.event.name }
                    </h5>
                    <p>{ props.event.date }</p>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>{ props.event.name }</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{ props.event.description }</Modal.Body>
                {props.event.recording
                  && (
                      <Modal.Footer>
                          {props.event.recording.map((record) => (
                              <Button variant="primary" href={record.link} target="_blank">{record.title}</Button>
                          ))}
                      </Modal.Footer>
                  )}
            </Modal>
        </div>
    );
};

export const ScheduleTabComponent = (props) => {
    const events = props.schedule.map((event, i) => <div key={i}><ScheduleTabEventComponent event={event} colour={colours[i % colours.length]} /></div>);

    if (events.length > 1) {
        return (
            <div>
                <Slider {...settingsLg} className="center gs-schedule-tab-event-slider d-none d-lg-block">
                    {events}
                    <div />
                    <div />
                </Slider>
                <Slider {...settings} className="center gs-schedule-tab-event-slider d-lg-none">
                    {events}
                </Slider>
            </div>
        );
    }
    return (
        <div className="slick-center">
            {events}
        </div>
    );
};

const ScheduleComponent = () => {
    const all = UofT.concat(UofW).concat(UWO);

    return (
        <Container fluid className="gs-block bg-light-blue" data-aos="fade-up">
            <h3 className="text-center">Past Events in 2020-2021</h3>
            <Tabs className="gs-tabs pt-2 pb-4" defaultActiveKey="all">
                <Tab eventKey="all" title="All Schools">
                    <ScheduleTabComponent schedule={all} title="all" />
                </Tab>
                <Tab eventKey="uoft" title="University of Toronto">
                    <ScheduleTabComponent schedule={UofT} title="uoft" />
                </Tab>
                <Tab eventKey="uofw" title="University of Waterloo">
                    <ScheduleTabComponent schedule={UofW} title="uofw" />
                </Tab>
                <Tab eventKey="uwo" title="University of Western Ontario">
                    <ScheduleTabComponent schedule={UWO} title="uwo" />
                </Tab>
            </Tabs>
        </Container>
    );
};

export default ScheduleComponent;
