export const EPISODES = {
    'episode-0':
        {
            id: 'episode-0',
            title: 'CC:World Episode 0: A Sitdown with Malik Ismail, the founder of Global Spark',
            description: "In the first episode of CC: World, we sit down with the founder and executive director of Global Spark, Malik Ismail. We delve into his motivation for founding Global Spark, how it influenced his career, and how  Global Spark is preparing the next generation of leaders to solve the world's biggest problems.",
            AppleLink: 'http://apple.co/3qrtXKq',
            GoogleLink: 'http://bit.ly/3qxgNvC',
            YouTubeLink: 'http://bit.ly/37iJ5lI',
            SpotifyLink: 'https://open.spotify.com/episode/6pzVSNXaiY3vK6qFxwHT0S?si=9b4110f0d86442c4',
            spotifyEmbed: 'https://open.spotify.com/embed/episode/6pzVSNXaiY3vK6qFxwHT0S?utm_source=generator&theme=0',
            showNotes: [
                'Welcome to the introductory episode of CC: World, a podcast by Global Spark that hosts conversations with global experts about cutting-edge solutions to our world\'s most pressing problems using interdisciplinary problem solving, creativity, and collaboration.',
                'In the introductory episode, we sat down with Malik Ismail, the founder and executive director of Global Spark, a leading education non-profit that mobilizes thousands of university students in STEM, social sciences and business in social entrepreneurship and global development. We talk with Ismail about why he founded Global Spark, the work Global Spark does, and how that ties into his career and aligns with the priorities of the world\'s top companies.',
                '<strong class="podcast-showNotes-bold">How Ismail\'s experience as a University of Toronto Engineering student sparked his decision to create Global Spark</strong>',
                'Malik Ismail is a former consultant at the Boston Consulting Group and is now at Google X. He founded Global Spark in the final year of his undergraduate engineering degree at the University of Toronto. Ismail\'s rationale behind founding Global Spark came from the observations he made about the engineering classes he took during his undergrad.',
                'First, he noticed that the curriculum focused on having students develop solutions to engineering problems that were applicable to an urban, modern environment. However, he noted that most of the issues the world is facing need solutions that go beyond urban cities like Toronto. Malik explained that “really solving those problems effectively requires a completely different approach to problem solving because the way you have to think about those problems are different.” A different way of thinking is needed to develop solutions to global challenges. He noted that students are taught about issues facing a city like Toronto and how to apply their skills to find a solution that is applicable to that context, but are not taught about global challenges or how to think about these issues.',
                'Secondly, he noticed that many engineering students only focused on technically sound solutions, and so there was a gap between solutions that were technically sound and ones that were thoughtful and feasible for the communities they were meant to serve. Malik described a time he was running a mini case study for hundreds of students about building a bridge for a village in Ghana. When asked about the factors they needed to consider, students discussed the technical analysis they would do. However, very few students discussed the non-technical factors that they would have to consider, such as the financing of the bridge, how you could get the materials to build the bridge, what are the needs of the village, etc. This experience made Ismail see the gap between students being able to design a technically sound solution, and design a solution that is practical for the community it\'s designed for.',
                'Many of us can resonate with this. We are taught subjects in isolation, from a single point of view. A single day in university could mean taking computer science, geography, and a finance class, for example, however these subjects are all taught from a single viewpoint rather than through a multidisciplinary lens. As students we aren\'t often encouraged to see how we could apply our knowledge from these three classes and from are other experiences to tackle solutions to global challenges and think about these issues in a multidisciplinary way. ',
                'Ismail founded Global Spark to address these two gaps that he observed: “the first was teaching engineering students about how they could apply their skillsets they were learning all of their courses and all of their experiences outside of the classroom to really tackle pressing global challenges like climate change or global health. The second was teaching them how to be multidisciplinary problem solvers that could go beyond the traditional four walls of technical analysis when trying to solve a problem and really build something that was sustainable and really impactful for the community it\'s being designed for.”',
                '<strong class="podcast-showNotes-bold">What makes Global Spark different - redesigning how students engage with the world\'s biggest problems</strong>',
                'With that goal of addressing these two gaps, Global Spark was created. Today, Global Spark is a leading education non-profit with the mission of preparing the next generation of leaders to solve the world\'s most complex problems. Global Spark has partnered with 11 universities and some of the world\'s top organizations such as Google, the Boston Consulting Group and the Bill and Melinda Gates Foundation to engage over 15,000 university students in innovative learning experiences that encourage multidisciplinary problem solving and collaboration to tackle global challenges.',
                'Malik discussed Hack the Globe, one of Global Spark\'s flagship events, as an example of how the organization has made progress on this mission. ',
                'Hack the Globe is one of a kind multidisciplinary social impact hackathon that gives students both the time and the guidance to build a thoughtful and impactful solution to global challenges. Rather than most hackathons that occur over a 24 or 48 hour period and focus on technical solutions, Hack the Globe is a 2 week long event where students have access to world class experts to generate a thoughtful solution to a global challenge. Additionally, the winners of the hackathon can bring their solution to life, and are connected with incubators and venture capital firms. Hack the Globe exemplifies how Global Spark has innovated a first of its kind learning experience for university students, and in fact, Ismail noted that after the first Hack the Globe event, they received feedback from students that this event forced them to think differently, which was the entire goal of the hackathon.',
                'Global Spark\'s influence on Ismail\'s Career and how Global Spark aligns with where the world\'s top companies are headed',
                'Ismail previously worked as a consultant at Boston Consulting Group. He explained that he was drawn to consulting because it enabled him to apply the rigorous problem solving skills he learned as an engineering student to difficult challenges facing companies and industries. Ismail embodies the Global Spark goal of using multidisciplinary thinking to solve global challenges. At BCG, he did just that, helping the world\'s biggest CEOs and leaders tackle global challenges around climate change and financial empowerment.',
                'Ismail noted that in the past few years, the world\'s top companies are focusing on these social challenges and are embedding the way they think about social issues like climate change into their day to day thinking and operations. CEOs and leaders are now asking themselves “how do we get our entire employee base....to innovate solutions around social impact?”. This focus on integrating social impact into the thinking of the company reflects the mission and work of Global Spark.  Ismail explained that “this type of approach that these business leaders are taking around the world has really reinforced what we\'re doing at Global Spark which is getting students, this next generation of the workforce and leaders of the world to think about how they can apply their skills on a day to day basis, to solving these global problems.” This reinforces how this multidisciplinary mindset and its application to thoughtfully solving global challenges are crucial skills for our generation to have.',
                'Ismail is now at X, the moonshot factory of Alphabet. He explained that he was drawn to X because of the types of problems they focus on - the biggest challenges facing humanity, and because of the way they think about tackling these issues, which is to come up with solutions that have 10X the impact, not a 10% impact. Additionally, Ismail\'s undergraduate and consulting career focused on climate change and using cutting-edge technology to generate solutions to climate change, and at X he will be continuing that work in terms of focusing on climate change.',
                'Connect with Global Spark',
                'Undoubtedly, engaging in a Global Spark experience will challenge you to think differently and apply your skills in a new way to tackle pressing global challenges. Global Spark is designed for students to engage students both in and out of the classroom through broad learning experiences. These include events such as design competitions and speaker with student organizations and universities,working with professors and institutional leaders to create curriculum for university students that focuses on multidisciplinary and global thinking, bringing together global thought leaders for a one of a kind panel about solving global issues by leveraging new technology, the CC: World podcast, which hosts conversations with global experts to share their perspective and experience coming up with cutting-edge solutions to these issues, and a one of a kind hackathon where students collaborate to generate solutions to global issues. You can learn more about Global Spark here. ',
                'You can connect with Malik Ismail on LinkedIn ',
                `The CC: World podcast will host conversations every month with global experts to share their perspective and experience in finding solutions to the world's biggest challenges, and how they wove together their careers with social impact.<br/>
                    You can listen to the CC: World podcast on <br/> 
                    Apple podcast: http://apple.co/3qrtXKq <br/>
                    Google Podcast: http://bit.ly/3qxgNvC <br/>
                    YouTube: http://bit.ly/37iJ5lI<br/>`,
            ],
        },

    'episode-1':
        {
            id: 'episode-1',
            title: "CC:World Episode 1: Amplifying Worker's Voices with Mobile Technology ",
            description: 'After observing that mobile phones were being used by people from the highlights of Peru to the steppes of Mongolia and Kazakhstan to mobilize on community-level issues, the founders of Ulula recognized the potential of these accessible technologies to amplify the voices of workers around the world. In this episode, we speak to Vera Belazelkoska, Director of Programs at Ulula, on the company’s work in supporting responsible and transparent business practices around the world through worker voice surveys and data analytics. Listen on to learn more about the issues in the global supply chain Ulula seeks to address, how the team collaborates across multiple disciplines, advice for students interested in pursuing a career in technology for social impact, and more!',
            AppleLink: 'http://apple.co/3qrtXKq',
            GoogleLink: 'http://bit.ly/3qxgNvC',
            YouTubeLink: 'http://bit.ly/37iJ5lI',
            SpotifyLink: 'https://open.spotify.com/episode/0QHg7CneGoqmiv9RyYFNwg?si=rBXHYCJgQNy0rDPzhplyfw',
            spotifyEmbed: 'https://open.spotify.com/embed/episode/0QHg7CneGoqmiv9RyYFNwg?utm_source=generator&theme=0',
            showNotes: [
                '<strong class="podcast-showNotes-bold">Show notes coming soon... </strong>',
            ],
        },

    'episode-2':
        {
            id: 'episode-2',
            title: 'CC:World Episode 2: Harnessing the Power of Business for Social Impact',
            description: 'As the Head of Marketing and Social Impact for Boston Consulting Group in Canada, Kate Banting leads a variety of social impact initiatives across topics such as measuring impact, diversity, education, youth development, mental health, and community engagement. She is also on the Board of Directors for The Child Development Institute, an accredited children’s mental health agency headquartered in Toronto that is focused on developing innovative programming. Kate holds an MBA with honours, from Harvard Business School and an HBA degree with distinction, from Richard Ivey School of Business.',
            AppleLink: 'http://apple.co/3qrtXKq',
            GoogleLink: 'http://bit.ly/3qxgNvC',
            YouTubeLink: 'http://bit.ly/37iJ5lI',
            SpotifyLink: 'https://open.spotify.com/episode/1o3wPfKu0ni3n0UsNLUtNx',
            spotifyEmbed: 'https://open.spotify.com/embed/episode/1o3wPfKu0ni3n0UsNLUtNx?utm_source=generator&theme=0',
            showNotes: [
                '<strong class="podcast-showNotes-bold">Show notes coming soon... </strong>',
            ],
        },
};
