import { PAST_LOCAL_EVENTS_IMAGE } from '../util/constants';

export const settingsLg = {
    centerMode: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    focusOnSelect: true,
};

export const settings = {
    centerMode: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    focusOnSelect: true,
    dots: true,
};

export const colours = ['0C2F61', 'FAD867', '4A2B7E'];

export const UofT = [
    {
        name: 'THE VACCINATION CONUNDRUM: Simulating Vaccine Distribution in Ontario',
        location: 'University of Toronto',
        description: `Global Spark hosted The Vaccination Conundrum in collaboration with the 
                    Munk School of Global Affairs and Public Policy. The event initiated the 
                    first partnership between the Engineering Faculty and the Munk School, 
                    specifically for Munk One students. The event was established as a 
                    platform for Engineering and Munk School students to bring together 
                    their shared knowledge, and create a truly interdisciplinary solution to 
                    the vaccine distribution in Ontario. Held virtually, 20+ Munk One and 
                    first year Engineering students attended, holding a range of roles from 
                    healthcare workers, public school boards, indigenous representatives, 
                    and private sector CEOs to brainstorm an effective and efficient 
                    vaccine distribution plan that would benefit all sides of Ontario. `,
        date: 'February 16, 2021',
        banner: PAST_LOCAL_EVENTS_IMAGE.uoft_vaccine,
    },
    {
        name: 'Global Spark x YNCN Fireside Chats: Social Impact in Industry',
        location: 'University of Toronto',
        description: `The Global Spark x YNCN Fireside Chat: Social Impact in the Industry 
                    introduced over 30 undergraduate engineering students to network with 
                    mentors who incorporated social impact when solving problems in industry. 
                    The 1.5 hour event, hosted in collaboration with You’re Next Career Network (YNCN), 
                    consisted of over 7 speakers from Lyft, Loblaws, United Nations, and the 
                    Ontario Ministry of Energy. This event provided students with the platform 
                    to engage with industry leaders and learn about their experiences, as well 
                    as to get advice for their career paths, specifically in social impact. 
                    Undergraduate students were able to have a close 1-to-1 conversation with 
                    mentors in a variety of industries ranging from energy, game development, 
                    to medical devices. The Fireside Chat gave a platform for students to form 
                    strong connections with our mentors, especially from fields they were not 
                    previously exposed to.`,
        date: 'January 28, 2021',
        banner: PAST_LOCAL_EVENTS_IMAGE.uoft_yncn,
    },
    {
        name: 'CASE COMPETITION - Innovative Solutions for Women in Rural Communities',
        location: 'University of Toronto',
        description: `Global Spark and WISE are excited to collaborate to organize a case 
                    competition event taking place from March 25-28, 2021, with the theme 
                    of women in global engineering. The competition aims to illuminate the 
                    unique challenges that women living in rural locations and developing 
                    countries face in their daily lives, and to encourage students 
                    to develop innovative solutions to tackle these challenges. 
                    The event included keynote speeches, 2 days to formulate a solution, 
                    and they were given the opportunity to present to an expert panel of judges.`,
        date: 'March 25 - 28, 2021',
        banner: PAST_LOCAL_EVENTS_IMAGE.uoft_innovative,
    },
    {
        name: 'Reaching the Hardest to Reach: Reach Alliance Info Session with Global Spark',
        location: 'University of Toronto',
        description: `Global Spark collaborated with The Reach Alliance to act as a consultant
                    to develop ways to inspire undergraduate students to follow their passions 
                    in social impact, and empower them to solve pressing issues around the world. 
                    The Reach Alliance is an international community of multidisciplinary student 
                    and faculty researchers investigating complex global challenges and addressing 
                    the UN Sustainable Development Goals. Engineering plays an important role 
                    in addressing the SDGs.`,
        date: 'March 11, 2021',
        banner: PAST_LOCAL_EVENTS_IMAGE.uoft_reach,
    },
];

export const UofW = [
    {
        name: 'Design for Accessibility Workshop',
        location: 'University of Waterloo',
        description: `What does it take to create an all-in-one experience? The answer lies with accessibility, 
                    one of the most important aspects of product design. This definition ranges from supporting 
                    various physical interactions to communicating with different disciplines. 
                    
                    Global Spark and UW/UX hosted “Design for Accessibility” where we’ll dive into the minds 
                    of the people behind up-and-coming and existing products, to be used by millions.We learn 
                    how you can also adopt an inclusive design mindset.`,
        date: 'Febuary 10, 2021',
        recording: [
            {
                title: 'Event Recording',
                link: 'https://drive.google.com/file/d/1PXV50ULjAMTNvVRC6R03jDzdwtZMxeG7/view?usp=sharing',
            },
        ],
        banner: PAST_LOCAL_EVENTS_IMAGE.uw_design,
    },
    {
        name: 'SDG (Sustainable Development Goals) Impact Challenge',
        location: 'University of Waterloo',
        description: `Are you ready to challenge yourself to make an impact in the Waterloo community? 
                    UW’s Impact Alliance and Global Spark hosted a virtual solutions hackathon called 
                    the "SDG Impact Challenge". This event partnered with three local organizations 
                    who are tackling some of the UN’s Sustainable Development Goals: Waterloo Institute 
                    for Sustainable Energy (WISE), the Interdisciplinary Centre on Climate Change (IC3) 
                    and Food4Kids. Teams of four chose one of the organizations’ problem statements 
                    and have 24 hours to develop an innovative solution to address it, then present 
                    it to the judges. The winning teams will also have a chance to work with Impact 
                    Alliance in Spring 2021 to implement their proposed solution in the Waterloo Region! `,
        date: 'March 20, 2021',
        recording: [
            {
                title: 'Opening Ceremony',
                link: 'https://drive.google.com/drive/folders/1kmdCPX2fU8-7Edjz_CNjoPpeBS8UfbQd',
            },
            {
                title: 'Closing Ceremony',
                link: 'https://drive.google.com/drive/folders/11eP3-acYUBHV4qnwtNcjB4fJ3592U4_Z',
            },
        ],
    },
    {
        name: 'Building Products for Social Impact',
        location: 'University of Waterloo',
        description: `UW PM and Global Spark hosted a panel focusing on social impact with 
                    respect to product management. We’ve had some amazing panelists from Facebook, 
                    CZI, Duolingo and Microsoft.`,
        date: 'November 19, 2020',
        recording: [
            {
                title: 'Event Recording',
                link: 'https://drive.google.com/file/d/1fhVVQ2-Vyoc2Wsmnmt-mL4eqRSIpLgKV/view?fbclid=IwAR1Tig5KEjCF-jKR4mMXIt5g3P7RqBSJsa0vIuYm3YvE8rFND7OX1I2qRR4',
            },
        ],
        banner: PAST_LOCAL_EVENTS_IMAGE.uw_products,
    },
    {
        name: 'Women in the Tech Workplace',
        location: 'University of Waterloo',
        description: `Come join WiCS (Waterloo Women in Computer Science) and Global Spark for 
                    an exciting and informative speaker panel with inspiring guest speakers.
                    Our panelists will discuss their experiences as women in tech and their 
                    thoughts about integrating social good into the tech industry. Attendees 
                    will have time to ask our speakers questions after the discussion.  `,
        date: 'November 12, 2020',
        banner: PAST_LOCAL_EVENTS_IMAGE.uw_women_tech,
    },
    {
        name: 'Global Health x Tech Workshop',
        location: 'University of Waterloo',
        description: `Come learn about the role technology has within Global Health with Dimagi, 
                    a leading global social enterprise that offers technology for social impact in 
                    80+ countries around the world! `,
        date: 'November 6, 2020',
        banner: PAST_LOCAL_EVENTS_IMAGE.uw_health,
    },
    {
        name: 'Waterloo Engineering Competition',
        location: 'University of Waterloo',
        description: `The Waterloo Engineering Competition is an exciting one-day event where 
                    competitors will flex their engineering skills in teams of 4 in one of 
                    four categories: Junior Design, Senior Design, Consulting, or Programming. 
                    Teams will be presented with an engineering problem and have to develop a 
                    complete solution and present it to our esteemed panel of judges.`,
        date: 'November 14, 2020',
        banner: PAST_LOCAL_EVENTS_IMAGE.uw_eng_competition,
    },
    {
        name: 'ENVE/GEO Career Panel (Global Spark Panel)',
        location: 'University of Waterloo',
        description: `Meet Environmental Engineering Alumni in water, mining, building energy, 
                    and geotechnical industries! Learn about different careers and gain insights 
                    on the work you can do with your degree! `,
        date: 'April 10, 2021',
        banner: PAST_LOCAL_EVENTS_IMAGE.uw_geo,
    },
];

export const UWO = [
    {
        name: 'Enactus Hacks (Hackathon Event)',
        location: 'University of Western Ontario',
        description: `Global Spark collaborated with Enactus Hacks at Western, a social 
                      impact based hackathon that also focuses on the feasibility of the solutions. 
                      At this hackathon, groups of four are encouraged to build their own 24-hour 
                      startup for a chance at winning prize money! Global Spark enacted a new 
                      judging section that encapsulates Global Spark’s mission and values: Most 
                      Equitable Solution, encouraging hackathon attendees to think of a solution 
                      that would lower levels of inequality. `,
        date: 'Febuary 1 - 2, 2020',
    },
    {
        name: 'EWB & Excel Workshop (Design 1050)',
        location: 'University of Western Ontario',
        description: `Global Spark Local Events and Course Partnerships collaborated with 
                    Engineers Without Borders Western to run an Excel workshop in the first 
                    year course Design 1050. Over 600 students were taught how Excel could 
                    analyze data on a large number of issues surrounding the world, and 
                    how that analysis can enact impactful change! The workshop included a 
                    keynote speaker, and an engaging case study that was taught through 
                    immersive collaborative learning.`,
    },
    {
        name: 'Debate Society (Encouraging High School students to start debating)',
        location: 'University of Western Ontario',
        description: `Global Spark partnered with the UWO Debate Society to run an educational 
                    workshop on social impact and its role in the future. Former Canadian debate 
                    champion Ethan Curry was the keynote speaker, with over 70 students attending 
                    from Western and dozens of Ontario high schools. Ethan gave an interesting overview 
                    of different social issues like environmentalism & feminism and their history, 
                    concluding with a discussion of the viability of capitalism as an underlying 
                    social structure in the 21st century.`,
    },
];
