import React, { useEffect, useRef } from 'react';
import { Image } from 'react-bootstrap';
import './style.css';
import { HOME_IMAGE } from '../util/constants';

const RotatingGlobe = ({ rotation, currentPicture }) => {
    const imageRef = useRef(null);

    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.style.transition = 'transform 1.5s ease-out';
            imageRef.current.style.transform = `rotate(${rotation}deg)`;
        }
    }, [rotation]);

    // eslint-disable-next-line consistent-return
    const getImageStyle = (picture) => {
        switch (picture) {
        case HOME_IMAGE.landing_p[0]:
            return 'landing-cartoon1';
        case HOME_IMAGE.landing_p[1]:
            return 'landing-cartoon2';
        case HOME_IMAGE.landing_p[2]:
            return 'landing-cartoon3';
        default:
            return '';
        }
    };

    return (
        <div className="gs-home-globe w-35 w-sm-35 b-0 r-0 gs-description" style={{ position: 'relative' }}>
            <Image ref={imageRef} src={HOME_IMAGE.globe} fluid />
            <div
                style={{
                    position: 'absolute',
                    top: '70%',
                    left: '-1rem',
                    zIndex: 1,
                    transform: 'translateY(-60%)',
                    // width: '350px',
                    // height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Image
                    src={currentPicture}
                    className={getImageStyle(currentPicture)}
                    style={{ objectFit: 'contain' }} // Ensures the image fits within the defined area without distortion
                    fluid
                />
            </div>
        </div>
    );
};

export default RotatingGlobe;
