import React from 'react';
import {
    Col, Row, Container, Image,
} from 'react-bootstrap';
import { SICP_IMAGE } from '../util/constants';

const ClientsComponent = () => (
    <div>
        <Container className="gs-block client-bg" fluid>
            <Row className="pb-5 pt-5" data-aos="fade-up">
                <Col md={{ span: 8, offset: 2 }}>
                    <h3 className="pb-3 client-heading">Client Testimonials</h3>
                </Col>
            </Row>
            <Row className="justify-content-center align-items-stretch" data-aos="fade-up">
                <Col lg={4} md={12} className="mb-auto client-col1">
                    <p className="client-text mr-3">The calibre of students assigned on the projects were excellent. We commend the teams for their thoughtful project approach and their awareness of cultural differences. The organizers invested significant effort in ensuring the teams were well-prepared for success. We are actively working on implementing the valuable outcomes and recommendations provided by the teams. We definitely enjoyed being apart of the program.</p>
                    <Row className="pt-4 pl-3">
                        <Col md={7} className="pl-4">
                            <Image src={SICP_IMAGE.abigail_okyere} className="client-image" />
                            <h6 className="client-person-name">Abigal Okyere</h6>
                            <p className="client-type-name">Non Profit/Charity Partner</p>
                            <p className="client-org-name">The Graced Life Leadership Centre</p>
                        </Col>
                    </Row>
                </Col>
                <Col lg={4} md={12} className="client-col2 client-second-col">
                    <p className="client-text mr-3">Our experience with the SICP program was both valuable and rewarding. The students were incredibly smart, diligent, insightful and extremely impressive. They brought things to our attention that we hadn't thought about. They gave thorough, well designed presentations with actionable ideas and suggestions. It was a very worthwhile process and I am honoured that we were part of it.</p>
                    <Row className="pt-4 pl-3">
                        <Col md={7} className="pt-4 pl-4">
                            <Image src={SICP_IMAGE.heather_mactaggart} className="client-image" />
                            <h6 className="client-person-name">Heather MacTaggart</h6>
                            <p className="client-type-name">Non Profit/Charity Partner</p>
                            <p className="client-org-name">Classroom Connections</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
);

export default ClientsComponent;
