import React from 'react';
import { Row, Col } from 'react-bootstrap';

const JoinProgramComponent = () => (
    <Row className="pt-5 pb-5 justify-content-center align-items-center" data-aos="fade-up">
        <Col md={6} xs={10} className=" col-md-6 col-xs-6 text-center align-items-center">
            <h3 className="section-heading" style={{ marginBottom: '25px' }}>Join the SICP program!</h3>
            <p className="join-text" style={{ marginBottom: '30px' }}>
                Join us this summer for an&nbsp;
                <span className="font-bold">
                    unparalleled opportunity to drive real change!&nbsp;
                </span>
                This is your chance to work hands-on with our social impact partners, addressing pressing global challenges, guided by mentors from Global Spark and BCG!
            </p>
            <a className="custom-button" href="https://forms.gle/rkFzG8tTrpzaLV196" target="_blank" rel="noopener noreferrer">
                <p className="apply-now">Apply now!</p>
            </a>
        </Col>
    </Row>
);

export default JoinProgramComponent;
