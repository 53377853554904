import React from 'react';

import {
    Row, Col, Container, Tabs, Tab,
} from 'react-bootstrap';

import { HTG_IMAGE } from '../util/constants';

const SampleProjectComponent = () => (
    <Container fluid className="gs-block">
        <Container>
            <h3 className="text-center pb-3">Past Years Winners</h3>
            <Tabs className="gs-tabs pt-2 pb-4" defaultActiveKey="0">
                {/* 2023 winners */}
                <Tab eventKey="0" title="2023">
                    <Row data-aos="fade-up">
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box mb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.first2023})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            >
                                <div>
                                    <p><b>1st Place</b></p>
                                    <h4 className="pb-5">BagSHARE</h4>
                                    <p>
                                        This sustainable societies solution aimed to transform the resuable bag industry, one bag at a time by allowing consumers to borrow bags using a client app.
                                    </p>
                                    <p>
                                        William Qian, Wendy Zhao, Shaba Khan, Gerry Chen
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box mb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.second2023})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            >
                                <div>
                                    <p><b>2nd Place</b></p>
                                    <h4 className="pb-5">AdaptEd</h4>
                                    <p>
                                        This economic empowerment solution is an educational platform incorporating AI-powered tools to offer inclusive education for students with learning disabilities.
                                    </p>
                                    <p>
                                        Sydney Basil, Haven Townsend, Jenna Cockburn, Shubham Kumar, Saisha Batthula
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box pb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.third2023})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            >
                                <div>
                                    <p><b>3rd Place</b></p>
                                    <h4 className="pb-5">CheckMate</h4>
                                    <p>
                                        This economic empowerment solution aimed to alleviate loneliness among the elderly by introducing an app equipped with a voice call feature and cognitive games.
                                    </p>
                                    <p>
                                        Dionysia Balafa, Jacopo Chirico, Begona Palenzuela Herrera, Lile Zhang Zhuo
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab>

                {/* 2021 winners */}
                <Tab eventKey="1" title="2021">
                    <Row data-aos="fade-up">
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box mb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.winner2021})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            >
                                <div>
                                    <p><b>1st Place</b></p>
                                    <h4 className="pb-5">reuseableEats</h4>
                                    <p>
                                        This climate change solution aimed to reduce plastic waste generated from take-out orders through a reusable container-sharing subscription program for the restaurant industry.
                                    </p>
                                    <p>
                                        Catherine Tsang, Felicity Wang, Karan Vasdev, Stephanie Sha
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box mb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.runnerup2021})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            >
                                <div>
                                    <p><b>Runner Up</b></p>
                                    <h4 className="pb-5">Argent</h4>
                                    <p>
                                        This economic empowerment solution utilized a SMS-based chat bot to provide agricultural loans &amp; financing solutions for rural farmers.
                                    </p>
                                    <p>
                                        Georgia Bryson, Hassan El-Essawi, Jason Amri, Sean Cornelius
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box pb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.peoplechoice2021})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            >
                                <div>
                                    <p><b>People's Choice</b></p>
                                    <h4 className="pb-5">DermaSpect</h4>
                                    <p>
                                        This popular solution used image recognition AI to aid health care professionals diagnose patient skin health.
                                    </p>
                                    <p>
                                        Anunay Kumar, Ria Bhandari, Shreya Singh, Sidharth Jain
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab>

                {/* 2020 winners */}
                <Tab eventKey="2" title="2020">
                    <Row data-aos="fade-up">
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box mb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.winner})` }}
                            >
                                <div>
                                    <p><b>1st Place</b></p>
                                    <h4 className="pb-5">Connexus</h4>
                                    <p>
                                        This crisis response solution aimed to facilitate family reunification in crisis situations using image recognition technology.
                                    </p>
                                    <p>
                                        Hui Wen Zheng, Jai Aggarwal, Martina Facchin, Mia Nguyen
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box mb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.runnerup})` }}
                            >
                                <div>
                                    <p><b>Runner Up</b></p>
                                    <h4 className="pb-5">HemoGo!</h4>
                                    <p>
                                        This global health solution aimed to ensure a steady supply of blood donations through encouragement and donor retention.
                                    </p>
                                    <p>
                                        Bayaan Shalaby, Henry Xu, Sarika Goel, Sheree Zhang
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} data-aos="zoom-in" className="pb-md-5">
                            <div
                                className="gs-htg-hack-box pb-3"
                                style={{ backgroundImage: `linear-gradient(0deg, rgba(61, 61, 61, 0.5), rgba(61, 61, 61, 0.5)), url(${HTG_IMAGE.peoplechoice})` }}
                            >
                                <div>
                                    <p><b>People’s Choice</b></p>
                                    <h4 className="pb-5">chickoo</h4>
                                    <p>
                                        This popular solution was an SMS platform that connected farmers in India to agricultural data specialized to their location and needs.
                                    </p>
                                    <p>
                                        Anna Wang, Chi-Hong Cheung, Jennifer Ge, Noah Simao
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    </Container>
);

export default SampleProjectComponent;
