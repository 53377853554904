import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    Row, Col, Card, Image, Container,
    Tabs, Tab,
} from 'react-bootstrap';
import { speakers2021, speakers2020, speakers2019 } from './util';

const PanelistsPanel = (props) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Col data-aos="zoom-in">
            <Card className="pb-4">
                <div className="text-center">
                    <Image src={props.speaker.img} onClick={() => setShowModal(true)} fluid />
                    <Card.Body className="p-0 pt-3">
                        <p className="text-center">
                            <b>{props.speaker.name}</b>
                            <br />
                            {props.speaker.title}
                        </p>
                    </Card.Body>
                </div>
            </Card>
            <Modal dialogClassName="gs-panelists-modal" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col lg={4} md={5} sm={6} xs={5} className="pull-left mt-3 mb-3 image-format">
                                <Image src={props.speaker.img} fluid />
                            </Col>
                            <Col lg={8} md={7} sm={6} xs={7} className="mt-3 text-image-alignment">
                                <p className="text-left">
                                    <b>{props.speaker.type}</b>
                                </p>
                                <h4 className="text-left mt-3">
                                    {props.speaker.title}
                                </h4>
                                <p className="text-left">
                                    {props.speaker.name}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <p className="text-left pl-3 pr-3 mt-2">{props.speaker.desc}</p>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Col>
    );
};

const Panelists2019Component = () => {
    const speakerImages = speakers2019.map((speaker, i) => <PanelistsPanel key={i} speaker={speaker} />);

    return (
        <Container className="gs-block">
            <h3 className="text-center pb-3 mt-n5" data-aos="fade-up">
                AI &amp; Big Data in Global Development
            </h3>
            <Row lg={4} md={2} xs={1} className="d-flex justify-content-center gs-panelists gs-panelists-2020 pt-3" data-aos="fade-up">
                {speakerImages[0]}
                {speakerImages[1]}
                {speakerImages[2]}
                {speakerImages[3]}
            </Row>
            <Row lg={4} xs={1} className="d-flex justify-content-center gs-panelists  gs-panelists-2020" data-aos="fade-up">
                <Col />
                <Col>
                    <h3 className="text-center pb-3">Moderated by:</h3>
                </Col>
                <Col>
                    {speakerImages[4]}
                </Col>
                <Col />
            </Row>
        </Container>
    );
};

const Panelists2020Component = () => {
    const speakerImages = speakers2020.map((speaker, i) => <PanelistsPanel key={i} speaker={speaker} />);
    return (
        <div>
            <Container className="gs-block">
                <h3 className="text-center pb-3 mt-n5" data-aos="fade-up">
                    AI &amp; Big Data in Global Development
                </h3>
                <Row lg={4} md={2} xs={1} className="d-flex justify-content-center gs-panelists gs-panelists-2020 pt-3" data-aos="fade-up">
                    {speakerImages[0]}
                    {speakerImages[1]}
                    {speakerImages[2]}
                    {speakerImages[3]}
                </Row>
                <Row lg={4} xs={1} className="d-flex justify-content-center gs-panelists  gs-panelists-2020" data-aos="fade-up">
                    <Col />
                    <Col>
                        <h3 className="text-center pb-3">Moderated by:</h3>
                    </Col>
                    <Col>
                        {speakerImages[4]}
                    </Col>
                    <Col />
                </Row>
            </Container>
            <Container className="gs-block">
                <Row className="mt-n5">
                    <Col md={5} data-aos="fade-right">
                        <h3 className="text-center text-md-right text-dark-blue pb-3">
                            2020 Speaker Panel Highlights
                        </h3>
                    </Col>
                    <Col md={7} data-aos="fade-left">
                        <p>
                            AI &amp; Big Data are disrupting the world and have tremendous potential to help address global development challenges.
                            We brought together thought leaders in the field to share their unique insights into building and implementing
                            successful solutions, and identify areas of promise for future AI &amp; Big Data solutions. We heard an in-depth conversation
                            about the challenges of implementing these solutions and the importance of considering the most marginalized people in
                            the design of solutions.
                            <br />
                            <br />
                            One of the key insights shared was about the potential of AI &amp; Big Data to reach the poorest, most marginalized people.
                            For example, providing access to healthcare by using machine learning for diagnostics. If designed correctly, we can
                            overcome geographic barriers and provide information, services, and opportunities to populations who have historically
                            been excluded.
                            <br />
                            <br />
                            We also heard discussions about how to build trust in AI systems from the stakeholders that will be using the product.
                            A good algorithm or product is not sufficient. The implementation and design must be tailored for the people that it
                            will be engaging and empowering.
                            <br />
                            <br />
                            The discussion was far-reaching and above are just a few of the pearls of wisdom shared by our panelists. We look forward
                            to continuing to bring unique perspectives on the role of technology in addressing global development challenges with our
                            annual panel.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const Panelists2021Component = () => {
    const speakerImages = speakers2021.map((speaker, i) => <PanelistsPanel key={i} speaker={speaker} />);
    return (
        <div>
            <Container className="gs-block">
                <h3 className="text-center pb-3 mt-n5" data-aos="fade-up">
                    Innovation in Global Health
                </h3>
                <Row lg={4} md={2} xs={1} className="d-flex justify-content-center gs-panelists gs-panelists-2020 pt-3" data-aos="fade-up">
                    {speakerImages[0]}
                    {speakerImages[1]}
                    {speakerImages[2]}
                </Row>
                <Row lg={4} xs={1} className="d-flex justify-content-center gs-panelists  gs-panelists-2020" data-aos="fade-up">
                    <Col />
                    <Col>
                        <h3 className="text-center pb-3">Moderated by:</h3>
                    </Col>
                    <Col>
                        {speakerImages[3]}
                    </Col>
                    <Col />
                </Row>
            </Container>
        </div>
    );
};

const PrevPanelistsComponent = () => (
    <Container className="gs-block pb-0">
        <h3 className="text-center text-dark-blue">Previous Panels</h3>
        <Tabs
            className="gs-tabs pt-2 pb-4"
            defaultActiveKey="0"
        >
            <Tab eventKey="0" title="2021">
                <Panelists2021Component />
            </Tab>
            <Tab eventKey="1" title="2020">
                <Panelists2020Component />
            </Tab>
            <Tab eventKey="2" title="2019">
                <Panelists2019Component />
            </Tab>
        </Tabs>
    </Container>
);

export default PrevPanelistsComponent;
