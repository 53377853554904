/**
 * Return a boolean to indicate whether the time has passed the deadline
 * of a specified period.
 * @param {"APPLICATION_PERIOD" | "THEME_SELECTION_PERIOD" | "HACKATHON_PERIOD"} periodType - A period with start and end dates.
 * @param {number} dateTimeNow - The current date time as the number milliseconds since epoch obtained from Date.now().
 * @returns {boolean} a boolean value to indicate whether the time passed as an argument
 * has passed the deadline of the specified period.
 */
export const isDuringActivePeriod = (periodType, dateTimeNow) => {
    let startDateTime = 0;
    let closeDateTime = 0;

    switch (periodType) {
    case 'APPLICATION_PERIOD':
        // EST UTC offset = -05:00
        startDateTime = Date.parse(process.env.REACT_APP_HTGAPP_STARTTIME);
        closeDateTime = Date.parse(process.env.REACT_APP_HTGAPP_CLOSETIME);
        break;
    case 'THEME_SELECTION_PERIOD':
        startDateTime = Date.parse(process.env.REACT_APP_HTGAPP_STARTTIME);
        // Change the below to the updated theme selection deadine
        closeDateTime = Date.parse(process.env.REACT_APP_HTGAPP_CLOSETIME);
        break;
    case 'HACKATHON_PERIOD':
        startDateTime = Date.parse(process.env.REACT_APP_HACKATHON_STARTTIME);
        closeDateTime = Date.parse(process.env.REACT_APP_HACKATHON_CLOSETIME);
        break;
    default:
        startDateTime = Date.parse(process.env.REACT_APP_HTGAPP_STARTTIME);
        closeDateTime = Date.parse(process.env.REACT_APP_HTGAPP_CLOSETIME);
        break;
    }

    const isActive = dateTimeNow >= startDateTime && dateTimeNow < closeDateTime;

    return isActive;
};
