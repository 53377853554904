import React from 'react';
import {
    Col, Row, Container, Image,
} from 'react-bootstrap';
import { SICP_IMAGE } from '../util/constants';

const HowItWorksComponent = () => (
    <div>
        <Container className="gs-block how-it-works-bg" fluid>
            <Container>
                <Row className="pb-4 align-items-center" data-aos="fade-up">
                    <Col md={{ offset: 2, span: 8 }}>
                        <h3 className="pb-3 section-heading">SICP provides students with hands-on consulting experience with non-profits</h3>
                    </Col>
                </Row>
                <Row className="pb-3" data-aos="fade-up">
                    <Col md={{ offset: 2, span: 8 }}>
                        <Row className="gs-sicp-circle-img-pill d-flex align-items-center justify-content-center">
                            <Image src={SICP_IMAGE.rocket} className="gs-sicp-circle-img " fluid />
                            <Col md={10}>
                                <h5 className="bullet-heading">32 Students</h5>
                                <p className="bullet-text">
                                    We have selected the best and brightest students from across Canada (100+ applicants) to bring fresh insights and out-of-the-box thinking
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-3" data-aos="fade-up">
                    <Col md={{ offset: 2, span: 8 }}>
                        <Row className="gs-sicp-circle-img-pill d-flex align-items-center justify-content-center">
                            <Image src={SICP_IMAGE.calendar} className="gs-sicp-circle-img" fluid />
                            <Col md={10}>
                                <h5 className="bullet-heading">12 Weeks</h5>
                                <p className="bullet-text">
                                    This program is designed to be an interactive and rewarding learning experience, with a program of events supporting the consulting project
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-3" data-aos="fade-up">
                    <Col md={{ offset: 2, span: 8 }}>
                        <Row className="gs-sicp-circle-img-pill d-flex align-items-center justify-content-center">
                            <Image src={SICP_IMAGE.trophy} className="gs-sicp-circle-img" fluid />
                            <Col md={10}>
                                <h5 className="bullet-heading">Social Impact</h5>
                                <p className="bullet-text">
                                    Our objective is to provide meaningful and impactful recommendations to deserving non-profits, while building future social impact leaders
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    </div>
);

export default HowItWorksComponent;
