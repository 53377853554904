import React, { useEffect } from 'react';

import {
    Tabs, Tab, Container, Col, Row,
} from 'react-bootstrap';
import TeamSectionComponent from './TeamSectionComponent';
import { teamSections } from './util';
import './style.css';

const TeamComponent = () => {
    useEffect(() => {
        document.title = 'Our Team';
    });

    return (
        <div>
            <Container className="gs-block">
                <h1 className="text-center">Our Team</h1>
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <p className="text-center">
                            Global Spark is run by a multidisciplinary team of over 110 young professionals and student leaders. Founded at the University of Toronto in 2017 by Malik Ismail, the organization has grown to be a global community, with volunteers from countries such as Canada, United Kingdom, Kenya and South Korea. Together, we work to provide opportunities and experiences to spark the next generation of leaders, ideas, and solutions to tackle the world’s most pressing challenges.
                        </p>
                    </Col>
                    <Col md={1} />
                </Row>
            </Container>
            <Container className="gs-block">
                <Tabs
                    defaultActiveKey="Leadership"
                    className="gs-team-tabs pt-2 pb-4"
                    id="uncontrolled-tab-example"
                >
                    <Tab eventKey="Leadership" title="Leadership">
                        <TeamSectionComponent teamSection={teamSections[0]} />
                    </Tab>

                    <Tab eventKey="HackTheGlobe" title="Hack The Globe">
                        <TeamSectionComponent teamSection={teamSections[1]} />
                    </Tab>
                    <Tab eventKey="Spark Ventures" title="Spark Ventures">
                        <TeamSectionComponent teamSection={teamSections[2]} />
                    </Tab>
                    <Tab
                        eventKey="PartnershipsSponsorships"
                        title="Sponsors & Partnerships"
                    >
                        <TeamSectionComponent teamSection={teamSections[3]} />
                    </Tab>

                    <Tab eventKey="Digital & Analytics" title="Digital & Analytics">
                        <TeamSectionComponent teamSection={teamSections[4]} />
                    </Tab>
                    <Tab eventKey="Marketing" title="Marketing">
                        <TeamSectionComponent teamSection={teamSections[5]} />
                    </Tab>
                    <Tab eventKey="PeopleOperations" title="People & Culture">
                        <TeamSectionComponent teamSection={teamSections[6]} />
                    </Tab>
                    <Tab eventKey="BoardAndAdvisors" title="Board and Advisors">
                        <TeamSectionComponent teamSection={teamSections[7]} />
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
};

export default TeamComponent;
